import {
  CommonSearchable,
  SearchIndex,
  TimeseriesSearchable,
} from "@properate/api/src/types";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { FacetHit, Hits } from "meilisearch";
import { useCurrentBuildingId } from "./useCurrentBuildingId";

const PAGE_SIZE = 100;
const SEARCH_DEBOUNCE_MS = 250;

function buildFilter(name: string, filter: string | string[]) {
  if (!(name ?? "").trim()) {
    return "";
  }
  if (Array.isArray(filter)) {
    return filter.map((value) => ` AND ${name} = "${value}"`).join("");
  }
  return filter ? ` AND ${name} = "${filter}"` : "";
}

function buildFilterQuery<T extends CommonSearchable = TimeseriesSearchable>(
  filter: Filter<T>,
  currentBuildingId: number,
) {
  const queryParts = [`buildingId = ${currentBuildingId}`];
  Object.entries(filter).forEach(([name, value]) => {
    queryParts.push(buildFilter(name, value));
  });
  return queryParts.join("");
}

export type FacetSearchHit = Record<
  string,
  {
    search: string;
    hits: FacetHit[];
  }
>;

export type SortBy = {
  key: string;
  order: "asc" | "desc";
};

export type Filter<T extends CommonSearchable> = Partial<T>;

export type SearchIndexResult<T extends CommonSearchable> = {
  facetHits: FacetSearchHit;
  searchHits: Hits<T>;
  page: number;
};

export type SearchIndexProps<T extends CommonSearchable> = {
  index: SearchIndex<T>;
  search: string;
  selectedFacets: Filter<T>;
  facetSearchInput: Filter<T>;
  sortBy?: SortBy;
  page?: number;
};

export function useSearchIndex<T extends CommonSearchable>({
  index,
  search,
  selectedFacets,
  facetSearchInput,
  sortBy,
  page = 0,
}: SearchIndexProps<T>): SearchIndexResult<T> {
  const [prevFilterQuery, setFilterQuery] = useState<string>();
  const [prevSearch, setSearch] = useState<string>();
  const [facetHits, setFacetHits] = useState<FacetSearchHit>();
  const [prevPage, setPage] = useState<number>();
  const [searchHits, setSearchHits] = useState<Hits<T>>();
  const currentBuildingId = useCurrentBuildingId();

  const facetFilterTimeout = useRef<number | null>(null);
  const searchTimeout = useRef<number | null>(null);

  function clearTimeoutRef(handle: MutableRefObject<number | null>) {
    if (handle.current !== null) {
      window.clearTimeout(handle.current);
      handle.current = null;
    }
  }

  useEffect(() => {
    clearTimeoutRef(facetFilterTimeout);
    facetFilterTimeout.current = window.setTimeout(async () => {
      for (const [name, filter] of Object.entries(facetSearchInput)) {
        if (facetHits?.[name]?.search !== filter) {
          const { facetHits: newFacetHits } = await index.searchForFacetValues({
            facetName: name,
            facetQuery: filter ?? "",
          });
          setFacetHits((prevFacetHits: FacetSearchHit | undefined) => ({
            ...(prevFacetHits || {}),
            [name]: {
              search: filter,
              hits: newFacetHits,
            },
          }));
        }
      }
    }, SEARCH_DEBOUNCE_MS);
  }, [
    // We only want to trigger this from user-input, more specifically facet searches.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    facetSearchInput,
  ]);

  useEffect(() => {
    clearTimeoutRef(searchTimeout);
    searchTimeout.current = window.setTimeout(async () => {
      const filterQuery = buildFilterQuery(selectedFacets, currentBuildingId);
      if (
        filterQuery !== prevFilterQuery ||
        search !== prevSearch ||
        page !== prevPage
      ) {
        const { hits: searchHits } = await index.search(search, {
          sort: sortBy ? [`${sortBy.key}:${sortBy.order}`] : undefined,
          filter: filterQuery,
          attributesToHighlight: ["*"],
          limit: PAGE_SIZE,
          offset: page * PAGE_SIZE,
        });
        setSearchHits(searchHits);
        setFilterQuery(filterQuery);
        setSearch(search);
        setPage(page);
      }
    }, SEARCH_DEBOUNCE_MS);
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    search,
    facetHits,
    page,
    selectedFacets,
  ]);

  return {
    facetHits: facetHits ?? {},
    searchHits: searchHits ?? [],
    page,
  };
}
