import { Space, Tooltip } from "antd";
import { ParentSize } from "@visx/responsive";
import { useTranslations } from "@properate/translations";
import { DraggableCard, WidgetHeader } from "@/features/dashboard";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { SpotPriceGranularity, SpotPriceTimeSpan } from "@/utils/types";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import {
  mutateUserSettings,
  useUserSettingsForBuilding,
} from "@/services/userSettings";
import { useGetSpotPriceData } from "../hooks/useGetSpotPriceData";
import { getHasMissingData, isCurrentDatapoint } from "../utils";
import { SpotPriceGraph } from "./SpotPriceGraph";
import { InfoCircleIcon, SpotPriceDataTopRightCorner } from "./elements";
import { SpotPriceTimeSpanSelect } from "./SpotPriceTimeSpanSelect";
import { SpotPriceGranularitySelect } from "./SpotPriceGranularitySelect";

interface Props {
  width: number;
  height: number;
  onClickRemoveButton: () => unknown;
}

export function SpotPriceWidget({ width, height, onClickRemoveButton }: Props) {
  const t = useTranslations();

  const headerHeight = 46;
  const currentBuildingId = useCurrentBuildingId();
  const { data: userSettingsForBuilding } = useUserSettingsForBuilding();
  const timeSpan =
    userSettingsForBuilding?.settingsSpotPrice?.timeSpan || "todayAndTomorrow";
  const granularity =
    userSettingsForBuilding?.settingsSpotPrice?.granularity || "1h";
  const { spotPriceDatapoints, isLoading } = useGetSpotPriceData(
    currentBuildingId,
    timeSpan,
    granularity,
  );
  const currentDatapoint = spotPriceDatapoints.find((datapoint) =>
    isCurrentDatapoint(datapoint, granularity),
  );
  const hasMissingData = getHasMissingData(timeSpan, spotPriceDatapoints);

  function handleChangeTimeSpan(value: SpotPriceTimeSpan) {
    const shouldChangeGranularity =
      (value === "last24Hours" || value === "todayAndTomorrow") &&
      granularity === "24h";
    mutateUserSettings({
      buildings: {
        [currentBuildingId]: {
          settingsSpotPrice: {
            timeSpan: value,
            ...(shouldChangeGranularity
              ? {
                  granularity: "1h",
                }
              : null),
          },
        },
      },
    });
  }

  function handleChangeGranularity(value: SpotPriceGranularity) {
    mutateUserSettings({
      buildings: {
        [currentBuildingId]: {
          settingsSpotPrice: {
            granularity: value,
          },
        },
      },
    });
  }

  return (
    <DraggableCard
      style={{ width, height }}
      bodyStyle={{
        height: height - headerHeight,
        position: "relative",
      }}
      title={
        <WidgetHeader
          text={t("dashboard.widgets.spot-price.title")}
          onClickRemoveButton={onClickRemoveButton}
          isDraggable
          addon={
            <Space>
              <SpotPriceTimeSpanSelect
                value={timeSpan}
                onChange={handleChangeTimeSpan}
              />
              <SpotPriceGranularitySelect
                value={granularity}
                onChange={handleChangeGranularity}
                timeSpan={timeSpan}
              />
              {hasMissingData && (
                <Tooltip
                  title={
                    timeSpan === "todayAndTomorrow"
                      ? t("dashboard.widgets.spot-price.tomorrow-description")
                      : t("dashboard.widgets.spot-price.next-day-description")
                  }
                >
                  <InfoCircleIcon />
                </Tooltip>
              )}
            </Space>
          }
        />
      }
    >
      <SpinnerWithDelay isLoading={isLoading} centerInParent>
        {spotPriceDatapoints.length > 0 && (
          <SpotPriceDataTopRightCorner
            datapoints={spotPriceDatapoints}
            timeSpan={timeSpan}
            currentDatapoint={currentDatapoint}
          />
        )}
        {spotPriceDatapoints.length > 0 ? (
          <ParentSize>
            {({ width, height }) => (
              <SpotPriceGraph
                width={width}
                height={height}
                datapoints={spotPriceDatapoints}
                granularity={granularity}
                currentDatapoint={currentDatapoint}
                timeSpan={timeSpan}
                hasMissingData={hasMissingData}
              />
            )}
          </ParentSize>
        ) : (
          t("dashboard.widgets.spot-price.no-price-info-found")
        )}
      </SpinnerWithDelay>
    </DraggableCard>
  );
}
