import { useNavigate } from "react-router-dom";
import { WithSnapshotId } from "@properate/common";
import { useTranslations } from "@properate/translations";
import { Gauge, GaugeView } from "@/features/gauges";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { DraggableCard } from "../draggable/DraggableCard";
import { WidgetHeader } from "../widget-components/WidgetHeader";

interface Props {
  gauge: WithSnapshotId<Gauge>;
  width: number;
  height: number;
  onClickRemoveButton: () => unknown;
}

export function GaugeWidget({
  gauge,
  width,
  height,
  onClickRemoveButton,
}: Props) {
  const t = useTranslations();
  const {
    timeseriesList: [timeseries],
  } = useGetTimeseriesList([gauge.timeseriesSettings.id]);
  const currentBuildingId = useCurrentBuildingId();
  const navigate = useNavigate();
  const headerHeight = 46;
  const { overrideUnits, isLoading: isLoadingOverrideUnit = false } =
    useTimeseriesSettings(currentBuildingId);

  return (
    <DraggableCard
      style={{ width, height }}
      bordered={false}
      title={
        <WidgetHeader
          isDraggable
          text={gauge.name}
          onClickRemoveButton={onClickRemoveButton}
          onClickSettingsButton={() =>
            navigate(
              `/asset/${currentBuildingId}/analysis/gauge/${gauge.snapshotId}`,
            )
          }
        />
      }
      bodyStyle={{
        display: "grid",
        placeContent: "center",
        height: `calc(100% - ${headerHeight}px)`,
      }}
    >
      {isLoadingOverrideUnit ? (
        <SpinnerWithDelay isLoading={isLoadingOverrideUnit}>
          {t("dashboard.widgets.loading")}
        </SpinnerWithDelay>
      ) : (
        <GaugeView
          max={gauge.max}
          min={gauge.min}
          viewMode={gauge.viewMode || "bar"}
          timeseries={timeseries}
          type={gauge.type}
          zones={gauge.zones}
          unit={
            (timeseries &&
              overrideUnits &&
              overrideUnits[timeseries.externalId!]?.unit) ||
            gauge.timeseriesSettings.unit
          }
          since={gauge.since}
          mathExpression={gauge.mathExpression}
          size="medium"
        />
      )}
    </DraggableCard>
  );
}
