import { Button, Tooltip } from "antd";
import { useUser } from "@properate/auth";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { useBuildingModulesAccess } from "@/services/featureAccess/useAccessModuleFeatureAccess";
import { AccessModules } from "@/features/organizationAdmin";
import { AnalysisCreateRedirectButtonProps } from "@/utils/types";
import { createSimpleAnalysis } from "@/pages/common/utils";

export function AnalysisCreateRedirectButton({
  buildingId,
  timeseries,
  yAxisSettings,
  style,
}: AnalysisCreateRedirectButtonProps) {
  const user = useUser();
  const navigate = useNavigate();
  const { accessModules } = useBuildingModulesAccess();
  const t = useTranslations();

  async function createSimpleAnalysisAndRedirectToAnalysisPage() {
    const redirectUrlToAnalysis = await createSimpleAnalysis({
      buildingId,
      timeseries,
      yAxisSettings,
      userEmail: user.email,
    });
    navigate(redirectUrlToAnalysis);
  }

  const hasUserAccessToAnalysis = accessModules.includes(
    AccessModules.analysis,
  );

  return (
    hasUserAccessToAnalysis && (
      <Tooltip
        title={t("analysis.timeseries-graph.create-analysis-button-text")}
      >
        <Button
          onClick={createSimpleAnalysisAndRedirectToAnalysisPage}
          style={style}
        >
          {t("analysis.timeseries-graph.create-analysis-button-text")}
        </Button>
      </Tooltip>
    )
  );
}
