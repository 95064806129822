import { useEffect, useState } from "react";
import { Col, Modal, Radio, Row, Select } from "antd";
import { ParentSize } from "@visx/responsive";
import { getSystemCodeFromExternalId } from "@properate/common";
import { CogniteClient } from "@cognite/sdk";
import { useTranslations, MessageKey } from "@properate/translations";
import { getBuildingAssets } from "@/utils/helpers";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { mutateUserSettings } from "@/services/userSettings";
import { UserSettings } from "@/utils/types";
import { SankeyDiagram } from "@/components/SankeyDiagram/SankeyDiagram";
import { SelectOrganization } from "@/components/SelectOrganization/SelectOrganization";
import { WidgetHeader } from "../widget-components/WidgetHeader";
import { DraggableCard } from "../draggable/DraggableCard";

const TITLE_FROM_PERIOD: { [key: string]: MessageKey } = {
  "24h": "dashboard.widgets.energy-flow.titles-by-time.24h",
  "168h": "dashboard.widgets.energy-flow.titles-by-time.168h",
  "720h": "dashboard.widgets.energy-flow.titles-by-time.720h",
};

const PERIODS: { value: "24h" | "168h" | "720h"; labelKey: MessageKey }[] = [
  { value: "24h", labelKey: TITLE_FROM_PERIOD["24h"] },
  { value: "168h", labelKey: TITLE_FROM_PERIOD["168h"] },
  { value: "720h", labelKey: TITLE_FROM_PERIOD["720h"] },
];

interface Props {
  id: number;
  height: number;
  preferences?: UserSettings;
  onClickRemoveButton: () => unknown;
}

export function EnergyFlowWidget({
  id,
  height,
  preferences,
  onClickRemoveButton,
}: Props) {
  const t = useTranslations();

  const [showSettings, setShowSettings] = useState(false);
  const [organizations, setOrganizations] = useState<string[]>([]);
  const { client } = useCogniteClient();

  useEffect(() => {
    const load = async (client: CogniteClient) => {
      const total = (
        await getBuildingAssets({
          client,
          id,
          labels: ["oe_common_main_meter"],
        })
      ).sort((a, b) =>
        getSystemCodeFromExternalId(a.externalId!).localeCompare(
          getSystemCodeFromExternalId(b.externalId!),
        ),
      );

      setOrganizations(total.map((asset) => asset.metadata!.organization));
    };
    load(client);
  }, [client, id]);

  const userPreferences = preferences?.buildings?.[id];
  const unit = userPreferences?.sankey?.unit || "kWh";
  const period = userPreferences?.sankey?.period || "168h";
  const organization =
    userPreferences?.sankey?.organization || organizations[0];
  const padding = 24;
  const headerHeight = 46;

  const handleChangeOrganization = async (organization: string) => {
    await mutateUserSettings({
      buildings: {
        [id]: {
          sankey: {
            organization,
          },
        },
      },
    });
  };

  return (
    <ParentSize>
      {({ width }) => (
        <DraggableCard
          style={{ height: height }}
          bordered={false}
          bodyStyle={{ position: "relative", padding }}
          title={
            <WidgetHeader
              text={`${t(TITLE_FROM_PERIOD[period])} - ${t(
                "energy.owner-name",
                {
                  name: organization || "",
                  index: organization === organizations[0] ? 0 : 1,
                },
              )}`}
              onClickRemoveButton={onClickRemoveButton}
              onClickSettingsButton={() => setShowSettings(true)}
              isDraggable
            />
          }
        >
          {organization && (
            <SankeyDiagram
              id={id}
              width={width}
              height={height - headerHeight}
              unit={unit}
              period={period}
              organization={organization}
            />
          )}
          {showSettings && (
            <Modal
              title={t(
                "dashboard.widgets.energy-flow.settings-for-energy-flow",
              )}
              open
              onOk={() => setShowSettings(false)}
              onCancel={() => setShowSettings(false)}
            >
              <div>
                <Row gutter={[8, 8]}>
                  <Col span={4}>
                    <label htmlFor="energyflow-organization">
                      <strong>
                        {t("dashboard.widgets.energy-flow.organization")}:
                      </strong>
                    </label>
                  </Col>
                  <Col span={20}>
                    <SelectOrganization
                      id="energyflow-organization"
                      organization={organization}
                      organizations={organizations}
                      handleChangeOrganization={handleChangeOrganization}
                    />
                  </Col>
                  <Col span={4}>
                    <label htmlFor="energyflow-period">
                      <strong>
                        {t("dashboard.widgets.energy-flow.period")}:
                      </strong>
                    </label>
                  </Col>
                  <Col span={20}>
                    <Select
                      id="energyflow-period"
                      options={PERIODS.map((item) => ({
                        label: t(item.labelKey),
                        value: item.value,
                      }))}
                      value={period}
                      onSelect={async (value: "24h" | "168h" | "720h") => {
                        await mutateUserSettings({
                          buildings: {
                            [id]: {
                              sankey: {
                                period: value,
                              },
                            },
                          },
                        });
                      }}
                    />
                  </Col>
                  <Col span={4}>
                    <label htmlFor="energyflow-unit">
                      <strong>
                        {t("dashboard.widgets.energy-flow.unit")}:
                      </strong>
                    </label>
                  </Col>
                  <Col span={20}>
                    <Radio.Group
                      id="energyflow-unit"
                      options={[
                        {
                          label: t("dashboard.widgets.energy-flow.show-kwh"),
                          value: "kWh",
                        },
                        {
                          label: t(
                            "dashboard.widgets.energy-flow.show-percent",
                          ),
                          value: "%",
                        },
                      ]}
                      onChange={async (event) => {
                        await mutateUserSettings({
                          buildings: {
                            [id]: {
                              sankey: {
                                unit: event.target.value,
                              },
                            },
                          },
                        });
                      }}
                      value={unit}
                      optionType="button"
                    />
                  </Col>
                </Row>
              </div>
            </Modal>
          )}
        </DraggableCard>
      )}
    </ParentSize>
  );
}
