import { browserFirestore } from "@properate/firebase";
import { SchemaType, SchemaTypes } from "@properate/common";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { deleteObject, ref, getStorage } from "firebase/storage";
import { useMemo } from "react";
import {
  useGetFirestoreCollection,
  useGetFirestoreDocument,
} from "@properate/ui";
import { debugging } from "@/utils/debugging";

export function useSchema(type: SchemaTypes, id: string) {
  const { data } = useGetFirestoreDocument<SchemaType>(
    useMemo(() => doc(collection(browserFirestore, type), id), [type, id]),
  );

  return data;
}

export function useSchemaByRootAssetId(
  type: SchemaTypes,
  buildingId: string | number,
) {
  const { data, isLoading, error } = useGetFirestoreCollection<SchemaType>(
    useMemo(
      () =>
        query(
          collection(browserFirestore, type),
          where(
            "rootAssetId",
            "==",
            typeof buildingId === "number"
              ? buildingId
              : parseInt(buildingId, 10),
          ),
        ),
      [type, buildingId],
    ),
  );

  if (!data) {
    return { data: [], isLoading: false, error: null };
  }

  return {
    data: data.filter((schema) => !schema.isArchived),
    isLoading,
    error,
  };
}

export async function addSchema(type: SchemaTypes, schema: SchemaType) {
  debugging.record("add schema", {
    patch: schema,
    schemaType: type,
  });
  return addDoc(collection(browserFirestore, type), { ...schema, version: 11 });
}

export async function updateSchema(
  type: SchemaTypes,
  id: string,
  schema: Partial<SchemaType>,
  merge = true,
) {
  if (schema.backgroundImage !== undefined) {
    const docRef = doc(collection(browserFirestore, type), id);
    const snapshot = await getDoc(docRef);
    const data = snapshot.data() as SchemaType;

    if (
      data.backgroundImage &&
      data.backgroundImage !== schema.backgroundImage
    ) {
      debugging.record("deleting schema backgroundImage", {
        schema_id: id,
        patch: schema,
        schemaType: type,
      });
      await deleteObject(ref(getStorage(), data.backgroundImage));
    }
  }

  debugging.record("updating schema", {
    schema_id: id,
    patch: schema,
    schemaType: type,
  });

  return setDoc(doc(collection(browserFirestore, type), id), schema, {
    merge,
  });
}

export async function archiveSchema(
  type: SchemaTypes,
  id: string,
  user: string,
) {
  const docRef = doc(collection(browserFirestore, type), id);
  const snapshot = await getDoc(docRef);
  const data = {
    ...snapshot.data(),
    archivedBy: user,
    archivedDate: new Date(),
    isArchived: true,
  };

  debugging.record("archive schema", {
    schema_id: id,
    patch: data,
    schemaType: type,
  });

  return setDoc(doc(collection(browserFirestore, type), id), data, {
    merge: true,
  });
}
