import { ExternalId, Timeseries } from "@cognite/sdk";
import { Col, Form, Row, Tag } from "antd";
import { useEffect, useState } from "react";
import {
  formatSubBuildingFromExternalId,
  getSystemCodeFromExternalId,
} from "@properate/common";
import { useTranslations, MessageKey } from "@properate/translations";
import { timeseriesIndex } from "@/eepApi";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { getBBFromExternalId } from "../../helpers/Utils";

const FormItem = Form.Item;

interface Props {
  externalId: ExternalId;
}

function TimeSeriesDetails(params: Props) {
  const t = useTranslations();
  const { client } = useCogniteClient();

  const [timeseries, setTimeseries] = useState<Timeseries | null>(null);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    const loadTimeseries = async (externalId: ExternalId) => {
      setTimeseries((await client.timeseries.retrieve([externalId]))[0]);
    };
    if (params.externalId) loadTimeseries(params.externalId);
  }, [client.timeseries, params.externalId]);

  useEffect(() => {
    const getTranslatedLabels = async (ts: Timeseries) => {
      const translatedLabels = (
        (await timeseriesIndex?.getDocument?.(ts.id)) as unknown as {
          translatedLabels: string[];
        }
      )?.translatedLabels;
      setLabels(translatedLabels);
    };

    if (timeseries) getTranslatedLabels(timeseries);
  }, [timeseries]);

  const system = getSystemCodeFromExternalId(params.externalId.externalId);
  const bb = params.externalId ? getBBFromExternalId(params.externalId) : null;

  return (
    <>
      <Row gutter={24}>
        {timeseries && (
          <Col span={24}>
            <FormItem label={t("calculation-flow.node-types.name")}>
              {timeseries.description}
            </FormItem>
          </Col>
        )}
        <Col span={8}>
          <FormItem label={t("calculation-flow.node-types.sub-building")}>
            {formatSubBuildingFromExternalId(params.externalId.externalId)}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label={t("calculation-flow.node-types.system")}>
            {system}{" "}
            {(Number(system.substring(0, 3)).toString() as MessageKey) !==
              t(
                `tfm.system.buildingPartNumber.${Number(
                  system.substring(0, 3),
                )}` as MessageKey,
              ) &&
              t(
                `tfm.system.buildingPartNumber.${Number(
                  system.substring(0, 3),
                )}` as MessageKey,
              )}
          </FormItem>
        </Col>
        {timeseries && (
          <>
            <Col span={8}>
              <FormItem label={t("calculation-flow.node-types.component")}>
                {bb &&
                  `${bb} (${t(("tfm.bb-descriptions." + bb) as MessageKey)})`}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label={t("calculation-flow.node-types.unit")}>
                {timeseries.unit}
              </FormItem>
            </Col>
          </>
        )}
        {timeseries &&
          timeseries.metadata &&
          "floor" in timeseries.metadata && (
            <Col span={8}>
              <FormItem label={t("calculation-flow.node-types.floor")}>
                {timeseries.metadata.floor}
              </FormItem>
            </Col>
          )}
        {timeseries &&
          timeseries.metadata &&
          ("room_no" in timeseries.metadata ||
            "room_name" in timeseries.metadata) && (
            <Col span={8}>
              <FormItem label={t("calculation-flow.node-types.room")}>
                {timeseries.metadata.room_no} ({timeseries.metadata.room_name})
              </FormItem>
            </Col>
          )}

        <Col span={24}>
          <FormItem label={t("calculation-flow.node-types.labels")}>
            {labels.map((label) => (
              <Tag key={label}>{label}</Tag>
            ))}
          </FormItem>
        </Col>
      </Row>
    </>
  );
}

export default TimeSeriesDetails;
