import { FileInfo } from "@cognite/sdk";
import { useMemo, useState } from "react";
import {
  Form,
  Radio,
  App,
  Row,
  Select,
  Modal,
  RadioChangeEvent,
  DatePicker,
  Tooltip,
  Button,
} from "antd";
import Checkbox from "antd/es/checkbox";
import styled from "styled-components";
import { useUser } from "@properate/auth";
import { useTranslations } from "@properate/translations";
import { disabledFutureDates } from "@/utils/helpers";
import {
  useLastHalfYearPresets,
  useLastXMonthPreset,
  useLastXQuarterPresets,
} from "@/utils/date";
import { reports } from "@/eepApi";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { ReactComponent as IndoorClimateSVG } from "./icon/indoorClimate.svg";

type Props = {
  openModal?: boolean;
  addFile: (files: FileInfo[]) => void;
  subBuildingsAndSensorsParamsForIndoorClimateReport?: {
    id: string;
    parameters: string[];
  }[];
};

const RadioGroupStyled = styled(Radio.Group)`
  &&.ant-radio {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.background};
    font-size: 12px;
    font-variant: tabular-nums;
    line-height: 1.66667;
    list-style: none;
    font-feature-settings: "tnum";
    /* position: relative; */
    top: 0.2em;
    display: inline-block;
    outline: none;
    cursor: pointer;
  }
`;

export const IndoorClimateBigButtons = ({
  openModal,
  addFile,
  subBuildingsAndSensorsParamsForIndoorClimateReport,
}: Props) => {
  const { notification } = App.useApp();
  const [open, setOpen] = useState(openModal);

  const [form] = Form.useForm();
  const user = useUser();
  const building = useCurrentBuilding();

  const [subBuildingIndex, setSubBuildingIndex] = useState(0);
  const [lang, setLang] = useState<"no" | "en">("no");
  const t = useTranslations();

  function showModal() {
    setOpen(true);
  }

  function handleCancel() {
    form.resetFields();
    setOpen(false);
  }

  async function generateReport() {
    const validated = await form.validateFields();
    if (!validated.errorFields) {
      try {
        const data = {
          building_list: [building!.externalId!],
          parameters: validated.parameters,
          sub_building_list: [
            subBuildingsAndSensorsParamsForIndoorClimateReport![
              validated.buildingIndex
            ],
          ].map((building) => building.id),
          building_levels: ["building", "floor", "sub_floor"],
          report_type: "indoor_climate",
          period_start: validated.period[0].startOf("hour").utc().valueOf(),
          period_end: validated.period[1].startOf("hour").utc().valueOf(),
          user: user.email,
          language: lang,
        };
        const result = await reports(data);

        addFile(result);

        notification.success({
          message: t(
            "reports.indoor-climate.create.notification-ordered-report",
          ),
        });
      } catch (error) {
        if (typeof error === "object" && error !== null && "message" in error) {
          console.warn("error", error);
          const errorMessage = String(error.message);
          notification.error({
            message: t(
              "reports.indoor-climate.create.failed-to-generate-report",
              { errorMessage },
            ),
          });
        }
      }
      form.resetFields();
      setOpen(false);
    }
  }
  const labels: Record<string, string> = {
    temperature: t("indoor-climate.temperature"),
    co2: "CO2",
    VOC: "VOC",
  };
  const isNotCompleteDigitalTwinCreated =
    !subBuildingsAndSensorsParamsForIndoorClimateReport ||
    subBuildingsAndSensorsParamsForIndoorClimateReport.length === 0 ||
    !subBuildingsAndSensorsParamsForIndoorClimateReport[0].parameters ||
    subBuildingsAndSensorsParamsForIndoorClimateReport[0].parameters.length ===
      0 ||
    false;

  function handleOnChange({ target: { value } }: RadioChangeEvent) {
    setLang(value);
  }

  const last3MonthPreset = useLastXMonthPreset(3);
  const last6MonthPreset = useLastXMonthPreset(6);
  const last12MonthPreset = useLastXMonthPreset(12);
  const last3QuarterPresets = useLastXQuarterPresets(3);
  const lastHalfYearPresets = useLastHalfYearPresets();

  const presets = useMemo(
    () => [
      last3MonthPreset,
      last6MonthPreset,
      last12MonthPreset,
      ...last3QuarterPresets,
      ...lastHalfYearPresets,
    ],
    [
      last3MonthPreset,
      last6MonthPreset,
      last12MonthPreset,
      last3QuarterPresets,
      lastHalfYearPresets,
    ],
  );

  return (
    <>
      <Row
        justify="center"
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        data-testid="create-indoor-climate-report"
      >
        <Tooltip
          title={
            isNotCompleteDigitalTwinCreated
              ? t(
                  "reports.indoor-climate.create.notification-not-complete-digital-twin-created",
                )
              : t("reports.indoor-climate.create.title")
          }
        >
          <Button
            size="large"
            style={{
              width: "calc(100% - 40px)",
              height: 116,
              margin: "0 10px",
            }}
            disabled={isNotCompleteDigitalTwinCreated}
            onClick={showModal}
          >
            <div style={{ textAlign: "center" }}>
              <IndoorClimateSVG />
              <div>
                <span>{t("reports.indoor-climate.create.title")}</span>{" "}
              </div>
            </div>
          </Button>
        </Tooltip>
      </Row>
      <Modal
        title={t("reports.indoor-climate.create.title")}
        open={open}
        onOk={generateReport}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          requiredMark={false}
          layout="vertical"
          initialValues={{ buildingIndex: subBuildingIndex }}
        >
          <p>{t("reports.indoor-climate.create.brief-description")}</p>
          <Form.Item
            label={t("reports.indoor-climate.create.building-label")}
            name="buildingIndex"
            rules={[
              {
                required: true,
                message: t(
                  "reports.indoor-climate.create.select-building-rules-label",
                ),
              },
            ]}
            id="building"
          >
            <Select
              value={subBuildingIndex}
              onChange={(newIndex) => setSubBuildingIndex(newIndex)}
              aria-labelledby="building"
            >
              {subBuildingsAndSensorsParamsForIndoorClimateReport &&
                subBuildingsAndSensorsParamsForIndoorClimateReport.map(
                  (val: any, index: number) => {
                    return (
                      <Select.Option value={index} key={val.id}>
                        {val.id}
                      </Select.Option>
                    );
                  },
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Språk" name="language" id="language">
            <RadioGroupStyled
              defaultValue={"no"}
              options={[
                {
                  label: t("reports.indoor-climate.create.norwegian-label"),
                  value: "no",
                },
                {
                  label: t("reports.indoor-climate.create.english-label"),
                  value: "en",
                },
              ]}
              onChange={handleOnChange}
              value={lang}
              aria-labelledby="language"
            />
          </Form.Item>
          <Form.Item
            label={t("reports.indoor-climate.create.parameters-label")}
            name="parameters"
            rules={[
              {
                required: true,
                message: t(
                  "reports.indoor-climate.create.select-parameter-rules-label",
                ),
              },
            ]}
            id="parameters"
          >
            <Checkbox.Group
              options={(
                (subBuildingsAndSensorsParamsForIndoorClimateReport &&
                  subBuildingsAndSensorsParamsForIndoorClimateReport[
                    subBuildingIndex
                  ] &&
                  subBuildingsAndSensorsParamsForIndoorClimateReport[
                    subBuildingIndex
                  ].parameters) ||
                []
              ).map((value: string) => ({ label: labels[value], value }))}
              aria-labelledby="parameters"
            />
          </Form.Item>
          <Form.Item
            label={t("reports.indoor-climate.create.period-label")}
            name="period"
            rules={[
              {
                required: true,
                message: t(
                  "reports.indoor-climate.create.select-period-rules-label",
                ),
              },
            ]}
            id="period"
          >
            <DatePicker.RangePicker
              disabledDate={disabledFutureDates}
              presets={presets}
              aria-labelledby="period"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
