import { useState } from "react";
import { ParentSize } from "@visx/responsive";
import { useTranslations } from "@properate/translations";
import { WidgetHeader, DraggableCard } from "@/features/dashboard";
import {
  mutateUserSettings,
  useUserSettingsForBuilding,
} from "@/services/userSettings";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { SolarCellProductionPeriod } from "../types";
import { SolarCellProductionSettingsModal } from "./SolarCellProductionSettingsModal";
import { SolarCellProductionSummary } from "./SolarCellProductionSummary";
import { SolarCellProductionGraph } from "./SolarCellProductionGraph";
import { GraphAndSummaryContainer } from "./elements";

interface Props {
  width: number;
  height: number;
  onClickRemoveButton: () => unknown;
}

export function SolarCellProductionWidget({
  width,
  height,
  onClickRemoveButton,
}: Props) {
  const t = useTranslations();

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const currentBuildingId = useCurrentBuildingId();
  const { data: userSettingsForBuilding } = useUserSettingsForBuilding();
  const timeseriesIds =
    userSettingsForBuilding?.settingsSolarCellProduction?.timeseriesIds;
  const period = userSettingsForBuilding?.settingsSolarCellProduction?.period;
  const maxCapacity =
    userSettingsForBuilding?.settingsSolarCellProduction?.maxCapacity;

  const headerHeight = 46;

  function handleOK({
    timeseriesIds,
    period,
    maxCapacity,
  }: {
    timeseriesIds?: number[];
    maxCapacity?: number;
    period: SolarCellProductionPeriod;
  }) {
    mutateUserSettings({
      buildings: {
        [currentBuildingId]: {
          settingsSolarCellProduction: {
            timeseriesIds: timeseriesIds || [],
            period,
            maxCapacity: typeof maxCapacity === "number" ? maxCapacity : null,
          },
        },
      },
    });
    setIsSettingsModalOpen(false);
  }

  const bodyPaddingBlock = 12;
  const bodyPaddingInline = 6;

  return (
    <>
      <DraggableCard
        style={{ width, height }}
        bodyStyle={{
          paddingBlock: bodyPaddingBlock,
          paddingInline: bodyPaddingInline,
          height: height - headerHeight,
        }}
        title={
          <WidgetHeader
            text={t("dashboard.widgets.solar-production.title")}
            onClickRemoveButton={onClickRemoveButton}
            onClickSettingsButton={() => setIsSettingsModalOpen(true)}
            isDraggable
          />
        }
      >
        {timeseriesIds && timeseriesIds.length > 0 && period ? (
          <GraphAndSummaryContainer>
            <ParentSize>
              {({ width }) => {
                return (
                  <SolarCellProductionGraph
                    timeseriesIds={timeseriesIds}
                    period={period}
                    width={width}
                    height={height - headerHeight - bodyPaddingInline * 2}
                  />
                );
              }}
            </ParentSize>
            <SolarCellProductionSummary
              timeseriesIds={timeseriesIds}
              period={period}
              maxCapacity={maxCapacity || undefined}
            />
          </GraphAndSummaryContainer>
        ) : (
          <div>
            {t(
              "dashboard.widgets.solar-production.select-one-or-more-time-series",
            )}
          </div>
        )}
      </DraggableCard>
      {isSettingsModalOpen && (
        <SolarCellProductionSettingsModal
          open={isSettingsModalOpen}
          timeseriesIds={timeseriesIds}
          period={period}
          maxCapacity={maxCapacity || undefined}
          onCancel={() => setIsSettingsModalOpen(false)}
          onOK={handleOK}
        />
      )}
    </>
  );
}
