import { useEffect, useState } from "react";
import { useTranslations } from "@properate/translations";
import { Box, BoxVariant } from "@/pages/alarms/details/components/InfoBox";
import { useFormValue } from "../../FormContext/hooks";
import { TimeseriesThresholdFormFields } from "../TimeseriesThreshold";
import {
  getAlarmTimeseries,
  TimeseriesWithAlarmAssetName,
} from "../../../utils/getAlarmTimeseries";
import { OffsetDifference, ResponseDelaySelect } from "./SelectOffset";
import { SelectSubBuilding } from "./SelectSubBuilding";

export enum FixedTimeseriesName {
  RoomTempHigh = "RT001-max",
  RoomTempLow = "RT001-min",
  VOC = "RY002-max",
  CO2 = "RY001-max",
  LeakProtection = "RH002-norm",
}

export function FixedTimeseriesThresholdDelayOnly({
  fixedTimeseries,
  hideOffset = false,
}: {
  fixedTimeseries: FixedTimeseriesName;
  hideOffset?: boolean;
}) {
  const [subBuilding] = useFormValue<number>(
    TimeseriesThresholdFormFields.SubBuilding,
  );
  const [timeseriesId, setTimeseriesId] = useFormValue<number>(
    TimeseriesThresholdFormFields.Timeseries,
  );
  const [alarmTimeseries, setAlarmTimeseries] = useState<
    TimeseriesWithAlarmAssetName[] | undefined
  >(undefined);
  const [unavailableType, setUnavailableType] = useState<boolean>(false);
  const t = useTranslations();

  function findTimeseries(
    key: keyof TimeseriesWithAlarmAssetName,
    value: unknown,
  ) {
    return (alarmTimeseries ?? []).find((ts) => ts[key] === value);
  }

  const timeseries = findTimeseries("id", timeseriesId);

  useEffect(() => {
    // Fetch fixed timeseries for selected building
    if (subBuilding === undefined || subBuilding === null) {
      return;
    }
    getAlarmTimeseries(subBuilding).then(setAlarmTimeseries);
  }, [subBuilding]);

  useEffect(
    // Set timeseries from fixed timeseries based on selected building and type
    () => {
      if (alarmTimeseries === undefined) {
        return;
      }
      const ts = findTimeseries("name", fixedTimeseries);
      if (!ts) {
        // if timeseries not found we should show an error to user.
        // TODO: Error-message
        setUnavailableType(true);
        return;
      }
      setTimeseriesId(ts.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alarmTimeseries],
  );

  function renderTimeseriesBasedFields() {
    if (unavailableType) {
      return (
        <Box variant={BoxVariant.Info}>
          <p>{t("alarm-details.common.error-messages.type-unavailable")}</p>
        </Box>
      );
    }
    if (!timeseries) {
      return null;
    }
    return (
      <>
        {hideOffset ? null : <OffsetDifference unit={timeseries.unit ?? ""} />}
        <ResponseDelaySelect />
      </>
    );
  }

  return (
    <>
      <SelectSubBuilding />
      {renderTimeseriesBasedFields()}
    </>
  );
}
