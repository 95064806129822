import useSWR from "swr";
import { bisector, extent } from "d3-array";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { scaleLinear, scaleTime } from "@visx/scale";
import dayjs from "@properate/dayjs";
import { useContext, useMemo } from "react";
import { Line, LinePath } from "@visx/shape";
import { curveNatural } from "@visx/curve";
import { ThemeContext } from "styled-components";
import { localPoint } from "@visx/event";
import { formatMeasurement } from "@properate/common";
import {
  defaultStyles,
  Tooltip,
  useTooltip,
  useTooltipInPortal,
} from "@visx/tooltip";
import { useTranslations } from "@properate/translations";
import { PRIMARY } from "@/utils/ProperateColors";
import { WidgetHeader } from "../../widget-components/WidgetHeader";
import { DraggableCard } from "../../draggable/DraggableCard";
import { WeatherSymbol } from "./WeatherSymbol";

interface Props {
  lat: number;
  long: number;
  width: number;
  height: number;
  onClickRemoveButton: () => unknown;
}

const fetcher = (url: string) => fetch(url, {}).then((r) => r.json());

export function WeatherWidget({
  lat,
  long,
  width,
  height,
  onClickRemoveButton,
}: Props) {
  const t = useTranslations();
  const themeContext = useContext(ThemeContext);
  const { data } = useSWR(
    `https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=${lat}&lon=${long}`,
    fetcher,
  );
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  const weatherSymbolsArray = (data?.properties?.timeseries || [])
    .slice(0, 48)
    .filter((point: any) => dayjs(point.time).hour() % 6 === 0)
    .map((current: any) => ({
      time: dayjs(current.time).valueOf() + 3 * 60 * 60 * 1000,
      symbol_code: current.data?.next_6_hours?.summary.symbol_code,
    }));

  const timeseries = (data?.properties?.timeseries || [])
    .slice(0, 48)
    .map((point: any) => ({
      time: dayjs(point.time).valueOf(),
      temperature: point.data.instant.details.air_temperature,
    }));

  // bounds
  const padding = 24;
  const header = 30;
  const graphWidth = width > 0 ? width - padding * 2 : 0;
  const graphHeight = height - padding * 2 - header;

  const yTop = 24;
  const yAxisWidth = 30;
  const xAxisHeight = 30;

  const xMax = graphWidth > 0 ? graphWidth - yAxisWidth : 0;
  const yMax = graphHeight - yTop - xAxisHeight;

  const timeScale = useMemo(() => {
    const times = timeseries.map((ts: any) =>
      dayjs(ts.time).valueOf(),
    ) as number[];
    return scaleTime({
      range: [0, xMax],
      round: true,
      domain: extent(times) as [number, number],
    });
  }, [xMax, timeseries]);
  const yScale = useMemo(() => {
    const temperatures = timeseries.map(
      (ts: any) => ts.temperature,
    ) as number[];
    const ex = extent([...temperatures, 0]) as [number, number];
    return scaleLinear<number>({
      range: [yMax, 0],
      round: true,
      domain: ex ? [ex[0] - 1, ex[1] + 1] : ex,
    });
  }, [yMax, timeseries]);

  const bisectDate = bisector<any, Date>((d) => new Date(d.time)).left;

  const getDataForEvent = (coords: any) => {
    const x0 = timeScale.invert(coords.x - yAxisWidth);
    const index = bisectDate(timeseries!, x0, 1);
    const d0 = timeseries![index - 1];
    const d1 = timeseries![index];
    let d: any = d0;
    if (d1 && d1.time) {
      d =
        x0.valueOf() - d0.time.valueOf() > d1.time.valueOf() - x0.valueOf()
          ? d1
          : d0;
    }
    return d;
  };

  const handleTooltip = (event: any) => {
    const coords = localPoint(event.target.ownerSVGElement, event) || {
      x: 0,
      y: 0,
    };
    const d: any = getDataForEvent(coords);

    if (typeof d.temperature === "number") {
      showTooltip({
        tooltipData: {
          date: dayjs(d.time).format("dddd H:mm"),
          value: formatMeasurement({ value: d.temperature, unit: "°C" }),
        },
        //coords.x,
        tooltipLeft: yAxisWidth + timeScale(d.time),
        tooltipTop: yScale(d.temperature) + yTop,
      });
    } else {
      hideTooltip();
    }
  };

  return (
    <div ref={containerRef}>
      <DraggableCard
        bordered={false}
        style={{ height, position: "relative" }}
        bodyStyle={{ padding }}
        title={
          <WidgetHeader
            text={t("dashboard.widgets.weather.title")}
            onClickRemoveButton={onClickRemoveButton}
            isDraggable
          />
        }
      >
        {data && (
          <svg width={graphWidth} height={graphHeight}>
            <AxisLeft
              top={yTop}
              left={yAxisWidth}
              scale={yScale}
              tickStroke={themeContext.neutral4}
              stroke={themeContext.neutral4}
              tickFormat={(d) => `${d}°`}
              numTicks={5}
              hideAxisLine
              hideTicks
              tickLabelProps={{
                fill: themeContext.neutral4,
              }}
            />
            <GridRows
              numTicks={5}
              scale={yScale}
              width={xMax}
              top={yTop}
              left={yAxisWidth}
              fill={themeContext.neutral7}
            />
            <AxisBottom
              top={yMax + yTop}
              left={yAxisWidth}
              scale={timeScale}
              numTicks={8}
              stroke={themeContext.neutral4}
              tickStroke={themeContext.neutral4}
              tickFormat={(date: any) => {
                const m = dayjs(date);
                return m.format("HH");
              }}
              tickLabelProps={{
                fill: themeContext.neutral4,
                scaleToFit: true,
              }}
            />
            <g>
              {weatherSymbolsArray &&
                weatherSymbolsArray.map(({ time, symbol_code }: any) => (
                  <WeatherSymbol
                    key={time}
                    x={timeScale(time) + yAxisWidth}
                    y={yTop - 45}
                    symbol={symbol_code}
                  />
                ))}
            </g>
            <g>
              <LinePath
                data={timeseries}
                x={(d: any) => yAxisWidth + timeScale(d.time)}
                y={(d: any) => yScale(d.temperature) + yTop}
                stroke={PRIMARY}
                strokeWidth={2}
                curve={curveNatural}
              />
            </g>
            <rect
              x={yAxisWidth}
              y={yTop}
              width={xMax}
              height={yMax}
              fill="transparent"
              onMouseMove={(event) => handleTooltip(event)}
              onMouseLeave={() => {
                hideTooltip();
              }}
            />
            {tooltipData && typeof tooltipTop === "number" && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: yTop }}
                  to={{ x: tooltipLeft, y: yTop + yMax }}
                  stroke={themeContext.neutral5}
                  strokeWidth={2}
                  pointerEvents="none"
                  strokeDasharray="5,2"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop + 1}
                  r={4}
                  fill="black"
                  fillOpacity={0.1}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                  pointerEvents="none"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop}
                  r={4}
                  fill={PRIMARY}
                  stroke="white"
                  strokeWidth={2}
                  pointerEvents="none"
                />
              </g>
            )}
          </svg>
        )}
      </DraggableCard>
      {tooltipOpen && (
        <>
          <TooltipInPortal
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={tooltipTop! + 16}
            left={tooltipLeft! + 12}
          >
            {(tooltipData as any)?.value}
          </TooltipInPortal>
          <Tooltip
            top={yTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              textAlign: "center",
              transform: "translate(-100%,-100%)",
              marginLeft: padding + 12,
            }}
          >
            {(tooltipData as any)?.date}
          </Tooltip>
        </>
      )}
    </div>
  );
}
