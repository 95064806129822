import useSWR, { Fetcher } from "swr";
import { useCallback, useEffect, useState } from "react";
import { CogniteClient, FileInfo } from "@cognite/sdk";
import { App, Button, Col, Input, Popconfirm, Row, Space, Spin } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  CloseOutlined,
  DownloadOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { Step } from "react-joyride";
import dayjs from "@properate/dayjs";
import styled from "styled-components";
import { useLoaderData, useParams } from "react-router-dom";
import { useUser } from "@properate/auth";
import { sortDate, sortString } from "@properate/ui";
import { useTranslations } from "@properate/translations";
import { ProperateHighlighter } from "@/components/properateHighlighter/ProperateHighlighter";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { CompactContent } from "@/components/CompactContent";
import { useHelp } from "@/context/HelpContext";
import { useOnboarding } from "@/utils/onboarding";
import { JoyrideWrapper } from "@/components/JoyrideWrapper/JoyrideWrapper";
import { useBuildingPageTitle } from "@/hooks/usePageTitle";
import { useCurrentBuilding } from "@/hooks/useCurrentBuilding";
import { WasteBigButtons } from "@/pages/reports/WasteBigButtons";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { useWindowSize } from "@/hooks/useWindowSize";
import { PAGE_LAYOUT_HEIGHT } from "@/utils/layout";
import { useBuildingModulesAccess } from "@/services/featureAccess/useAccessModuleFeatureAccess";
import { AccessModules } from "@/features/organizationAdmin";
import { ReportPage } from "@/pages/waste/types";
import { EnergyReport } from "./EnergyBigButtons";
import { VentilationBigButtons } from "./VentilationBigButtons";
import { IndoorClimateBigButtons } from "./IndoorClimateBigButtons";

const STEPS: Step[] = [];

const ESTIMATED_PAGE_CARDS_HEIGHT = 112;
const ESTIMATED_TABLE_HEADER_HEIGHT = 44;

const CardWrapper = styled.div`
  margin-bottom: 30px;
`;

export const Reports = () => {
  const t = useTranslations();
  const { notification, modal } = App.useApp();
  const { accessModules } = useBuildingModulesAccess();
  useBuildingPageTitle(t("reports.title"));
  const { height: windowHeight } = useWindowSize();
  const { reportToGenerate, startDate, endDate } = useParams() as {
    reportToGenerate: string;
    startDate: string;
    endDate: string;
  };
  const user = useUser();
  const { client } = useCogniteClient();
  const [search, setSearch] = useState<string>("");
  const [onboardingSteps, setOnboardingStepCompleted] = useOnboarding(STEPS);
  const { setHelp } = useHelp();
  const building = useCurrentBuilding();
  const [deletedReportId, setDeletedReportId] = useState<number | null>(null);
  const reports = useLoaderData() as ReportPage;

  const fileQuery = JSON.stringify({
    filter: {
      assetIds: [building.id],
      labels: { containsAll: [{ externalId: "properate_report" }] },
    },
  });
  const formatReportName = (data: any) => {
    const stringData = {
      sub_building_list:
        data?.sub_building_list instanceof Array
          ? data.sub_building_list.join(", ")
          : data?.sub_building_list,
      parameters:
        data?.parameters instanceof Array
          ? data.parameters.join(", ")
          : data?.parameters,
      ...data,
    };

    const formatIndoorClimateReport = (params: any) => {
      const subBuildingList = params.sub_building_list || "";
      const parameters = params.parameters ? `(${params.parameters})` : "";
      const periodStart = dayjs(parseInt(params.period_start)).format(
        "DD/MM YYYY",
      );
      const periodEnd = dayjs(parseInt(params.period_end)).format("DD/MM YYYY");
      const lang = params?.lang ? `(${params.lang})` : "";

      return t("reports.table-column-title.format-indoor-climate-report", {
        subBuildingList,
        parameters,
        periodStart,
        periodEnd,
        lang,
      });
    };

    const formatVentilationReport = (params: any) => {
      const periodStart = dayjs(parseInt(params.period_start)).format(
        "DD/MM YYYY",
      );
      const periodEnd = dayjs(parseInt(params.period_end)).format("DD/MM YYYY");

      return t("reports.table-column-title.format-ventilation-report", {
        periodStart,
        periodEnd,
      });
    };

    const formatWasteReport = (params: any) => {
      const periodStart = dayjs(parseInt(params.period_start)).format(
        "DD/MM YYYY",
      );
      const periodEnd = dayjs(parseInt(params.period_end)).format("DD/MM YYYY");

      return t("reports.table-column-title.format-waste-report", {
        periodStart,
        periodEnd,
      });
    };

    const formatEnergyReport = (params: any) => {
      const periodStart = dayjs(parseInt(params.period_start)).format(
        "DD/MM YYYY",
      );
      const periodEnd = dayjs(parseInt(params.period_end)).format("DD/MM YYYY");

      return t("reports.table-column-title.format-energy-report", {
        periodStart,
        periodEnd,
      });
    };

    if (data.report_type === "energy") {
      return formatEnergyReport(stringData);
    }

    if (data.report_type === "ventilation") {
      return formatVentilationReport(stringData);
    }
    if (data.report_type === "waste_report") {
      return formatWasteReport(stringData);
    }
    return formatIndoorClimateReport(stringData);
  };

  function isPositiveInteger(value: string) {
    return /^\d+$/.test(value);
  }

  const fetcher: Fetcher<FileInfo[], string> = async (query) => {
    const response = await client.files
      .list(JSON.parse(query))
      .autoPagingToArray({ limit: -1 });

    const parseDate = (date: string) => {
      try {
        return dayjs(date, "YYYY,M,D,0,0").valueOf() + "";
      } catch (e) {
        console.error("Could not parse date", date);
        return undefined;
      }
    };

    return response
      .filter((file) => file.metadata)
      .map((file) => ({
        ...file,
        metadata: {
          ...file.metadata!,
          report_type:
            !file.metadata!.report_type &&
            file.metadata!.functionId &&
            file.metadata!.sub_building_list
              ? "indoor_climate"
              : file.metadata!.report_type,
          period_start:
            !file.metadata!.period_start ||
            isPositiveInteger(file.metadata!.period_start)
              ? file.metadata!.period_start
              : parseDate(file.metadata!.period_start),
          period_end:
            !file.metadata!.period_end ||
            isPositiveInteger(file.metadata!.period_end)
              ? file.metadata!.period_end
              : parseDate(file.metadata!.period_end),
        } as Record<string, string>,
      }))
      .filter((f) => f.metadata.report_type);
  };

  const {
    data: files,
    error,
    mutate,
  } = useSWR(fileQuery, fetcher, { refreshInterval: 5 * 1000 });

  if (error) {
    console.error("error", error);
  }

  const addFile = useCallback(
    (added: FileInfo[]) => mutate([...(files || []), ...added]),
    [mutate, files],
  );

  useEffect(() => {
    setHelp({
      title: t("reports.title"),
      content: (
        <>
          <p>{t("reports.help.brief-description")}</p>
        </>
      ),
    });
  }, [setHelp, t]);

  const searchTerms = search.trim().split(" ");
  function renderFileStatus(file: FileInfo) {
    const { metadata } = file;
    const { status, message } = metadata || {};

    if (status === "Failed") {
      return (
        <span>
          {t("reports.table-column-title.notification-file-status-error", {
            message,
          })}
        </span>
      );
    }
    return <Spin />;
  }
  const filesIncludingFailedAndQueued = files
    ?.filter((report) => report?.metadata?.user === user.email)
    ?.filter((item) => item.name)
    ?.filter((item) => item?.metadata?.user)
    ?.filter(
      (item) =>
        !searchTerms ||
        searchTerms.every(
          (term: string) =>
            (item.name || "").toLowerCase().includes(term.toLowerCase()) ||
            (item?.metadata?.user || "")
              .toLowerCase()
              .includes(term.toLowerCase()),
        ),
    )
    ?.map((file) => {
      if (
        file.metadata!.status === "Failed" ||
        file.metadata!.status === "Queued"
      ) {
        const isReportDeleted = deletedReportId
          ? deletedReportId === file.id
          : false;
        return {
          ...file,
          action: true,
          nameOverride: (
            <Space>
              <span
                style={
                  file.metadata?.status === "Failed"
                    ? { textDecoration: "line-through" }
                    : {}
                }
              >
                {formatReportName(file.metadata)}
              </span>
              {renderFileStatus(file)}
            </Space>
          ),
          actionOverride: (
            <Space>
              <Button
                key={"retry-" + building.id}
                icon={<RetweetOutlined />}
                disabled={file.metadata?.status !== "Failed"}
                onClick={async (event) => {
                  event.stopPropagation();
                  try {
                    const data = {
                      ...file.metadata,
                      building_list: [building!.externalId!],
                      user: file.metadata?.user,
                    };
                    await client.files.delete([{ id: file.id }]);
                    // @ts-expect-error file.metadata is not typed properly
                    const result = await reports(data);

                    notification.success({
                      message: t(
                        "reports.table-column-title.notification-ordered-report-retry",
                      ),
                    });
                    await mutate([
                      ...files!.filter((f) => f.id !== file.id),
                      ...result,
                    ]);
                  } catch (error: any) {
                    const errorMessage = String(error.message);
                    notification.error({
                      message: t(
                        "reports.table-column-title.notification-ordered-report-retry-error",
                        { errorMessage },
                      ),
                    });
                  }
                }}
              >
                {t("reports.table-column-title.action-retry-button-label")}
              </Button>
              <Popconfirm
                placement="top"
                title={t("reports.table-column-title.action-delete-confirm")}
                onConfirm={(event) => {
                  if (event) {
                    deleteReport(
                      event,
                      file.id,
                      formatReportName(file.metadata),
                    );
                  }
                }}
                onCancel={(event) => {
                  if (event) {
                    event.stopPropagation();
                  }
                }}
                okText={t(
                  "reports.table-column-title.action-delete-confirm-yes",
                )}
                cancelText={t(
                  "reports.table-column-title.action-delete-confirm-no",
                )}
              >
                <Button
                  key={`delete-${building.id}`}
                  danger
                  disabled={user.isViewer || isReportDeleted}
                  loading={isReportDeleted}
                  onClick={(event) => event.stopPropagation()}
                  icon={<CloseOutlined />}
                />
              </Popconfirm>
            </Space>
          ),
        };
      }
      return file;
    });

  const deleteReport = async (
    event: React.MouseEvent,
    id: number,
    name: string,
  ) => {
    if (event) {
      event.stopPropagation();
    }
    if (files) {
      setDeletedReportId(id);
      try {
        await client.files.delete([{ id }]);
        await mutate(files.filter((file) => file.id !== id));
        setDeletedReportId(null);

        notification.success({
          message: t(
            "reports.table-column-title.notification-deleted-success",
            { name },
          ),
        });
      } catch (error) {
        setDeletedReportId(null);
        if (typeof error === "object" && error !== null && "message" in error) {
          const errorMessage = String(error.message);
          notification.error({
            message: t(
              "reports.table-column-title.notification-deleted-error",
              { errorMessage },
            ),
          });
        }
      }
    }
  };

  return (
    <>
      <PageHeader
        title={t("reports.title")}
        extra={[
          <Input.Search
            data-testid="search-for-reports"
            key="search"
            allowClear
            style={{ width: 300 }}
            onChange={(event) => setSearch(event.target.value)}
            aria-label={t("reports.toolbar.search")}
          />,
        ]}
      />

      <CompactContent>
        <CardWrapper>
          <Row justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            {accessModules.includes(AccessModules.waste) && (
              <Col span={6}>
                <WasteBigButtons
                  openModal={reportToGenerate === "waste"}
                  lang="no"
                  addFile={addFile}
                  startDate={startDate}
                  endDate={endDate}
                  wasteTimeseriesList={reports.wasteTimeseriesList}
                />
              </Col>
            )}
            {accessModules.includes(AccessModules.indoorClimate) && (
              <Col span={6}>
                <VentilationBigButtons
                  modal={modal}
                  lang="no"
                  addFile={addFile}
                />
              </Col>
            )}
            {accessModules.includes(AccessModules.indoorClimate) && (
              <Col span={6}>
                <IndoorClimateBigButtons
                  openModal={reportToGenerate === "indoorClimate"}
                  addFile={addFile}
                  subBuildingsAndSensorsParamsForIndoorClimateReport={
                    reports.subBuildingsAndSensorsParamsForIndoorClimateReport
                  }
                />
              </Col>
            )}
            {accessModules.includes(AccessModules.energy) && (
              <Col span={6}>
                <EnergyReport
                  openModal={reportToGenerate === "energy"}
                  lang="no"
                  addFile={addFile}
                />
              </Col>
            )}
          </Row>
        </CardWrapper>
        <TableWithoutDefaultSort
          rowKey="id"
          pagination={false}
          scroll={{
            y:
              windowHeight -
              PAGE_LAYOUT_HEIGHT -
              ESTIMATED_PAGE_CARDS_HEIGHT -
              ESTIMATED_TABLE_HEADER_HEIGHT,
            x: "max-content",
          }}
          columns={[
            {
              title: t("reports.table-column-title.name"),
              key: "name",
              sorter: (
                { metadata: metadataOne },
                { metadata: metadataTwo },
              ) => {
                const reportNameOne = formatReportName(metadataOne);
                const reportNameTwo = formatReportName(metadataTwo);
                return sortString(reportNameOne, reportNameTwo);
              },
              render: (_, file) => {
                return "nameOverride" in file ? (
                  file.nameOverride
                ) : (
                  <ProperateHighlighter
                    searchWords={search.split(" ")}
                    autoEscape
                    textToHighlight={formatReportName(file.metadata)}
                  />
                );
              },
            },
            {
              title: t("reports.table-column-title.owner"),
              key: "user",
              width: 260,
              sorter: (
                { metadata: metadataOne },
                { metadata: metadataTwo },
              ) => {
                return sortString(metadataOne?.user, metadataTwo?.user);
              },
              render: (_, { metadata }) => {
                return (
                  <ProperateHighlighter
                    searchWords={search.split(" ")}
                    autoEscape
                    textToHighlight={(metadata && metadata.user) || ""}
                  />
                );
              },
            },
            {
              title: t("reports.table-column-title.date"),
              key: "createdTime",
              defaultSortOrder: "ascend",
              sorter: (
                { createdTime: createdTimeOne },
                { createdTime: createdTimeTwo },
              ) => sortDate(createdTimeTwo, createdTimeOne),
              width: 150,
              render: (_, { createdTime }) =>
                createdTime && (
                  <p key={createdTime.valueOf()}>
                    {dayjs(createdTime).fromNow()}
                  </p>
                ),
            },
            {
              key: "action",
              width: 160,
              render: (_, file) => {
                const isReportDeleted = deletedReportId === file.id;
                return "actionOverride" in file ? (
                  file.actionOverride
                ) : (
                  <Space>
                    <Button
                      key={file.id}
                      onClick={async (event) => {
                        event.stopPropagation();
                        const [{ downloadUrl }] =
                          await client.files.getDownloadUrls([{ id: file.id }]);
                        window.open(downloadUrl);
                      }}
                      style={{ marginRight: "10px" }}
                      icon={<DownloadOutlined />}
                    >
                      {t(
                        "reports.table-column-title.action-download-button-label",
                      )}
                    </Button>
                    <Popconfirm
                      placement="top"
                      title={t(
                        "reports.table-column-title.action-delete-confirm",
                      )}
                      onConfirm={async (event) => {
                        if (event) {
                          await deleteReport(
                            event,
                            file.id,
                            formatReportName(file.metadata),
                          );
                        }
                      }}
                      onCancel={(event) => {
                        if (event) {
                          event.stopPropagation();
                        }
                      }}
                      okText={t(
                        "reports.table-column-title.action-delete-confirm-yes",
                      )}
                      cancelText={t(
                        "reports.table-column-title.action-delete-confirm-no",
                      )}
                    >
                      <Button
                        danger
                        disabled={user.isViewer || isReportDeleted}
                        loading={isReportDeleted}
                        onClick={(event) => event.stopPropagation()}
                        key={`delete-${file.id}`}
                        icon={<CloseOutlined />}
                      />
                    </Popconfirm>
                  </Space>
                );
              },
            },
          ]}
          dataSource={filesIncludingFailedAndQueued}
          onRow={(file) => ({
            onClick: async () => {
              if (!("action" in file)) {
                const response = await fetch(
                  `https://api.cognitedata.com/api/v1/projects/${process.env.REACT_APP_PROJECT_ID}/documents/${file.id}/preview/pdf/temporarylink`,

                  {
                    headers: client.getDefaultRequestHeaders(),
                  },
                );
                const link = await response.json();
                if (link.temporaryLink) {
                  window.open(link.temporaryLink);
                } else {
                  notification.error({
                    message: t(
                      "reports.table-column-title.action-error-preview-temporary-link",
                    ),
                  });
                }
              }
            },
          })}
          rowIsHoverable
        />

        {onboardingSteps && onboardingSteps.length > 0 && (
          <JoyrideWrapper
            content={onboardingSteps[0]}
            onClose={setOnboardingStepCompleted}
          />
        )}
      </CompactContent>
    </>
  );
};
