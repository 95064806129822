import { Badge, Popover } from "antd";
import { useTranslations } from "@properate/translations";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { WidgetHeader, DraggableCard } from "@/features/dashboard";
import { useGetBuildingCertifications } from "../hooks/useGetBuildingCertifications";
import {
  CertificationList,
  CertificationListItem,
  FlexHeader,
} from "./elements";
import { BuildingCertificationBadge } from "./BuildingCertificationBadge";
import { isAboutToExpire, isExpired } from "./utils/expiration";
import { getCertificationLabel } from "./utils/getCertificationLabel";

type Props = {
  height: number;
  width: number;
  onClickRemoveButton: () => unknown;
};

export function BuildingCertificationsWidget(props: Props) {
  const t = useTranslations();

  const buildingId = useCurrentBuildingId();
  const certifications = useGetBuildingCertifications(buildingId);
  const headerHeight = 46;

  const nonExpiredCertifications = certifications.data.filter(
    (certification) => !isExpired(certification),
  );

  return (
    <DraggableCard
      style={{
        width: props.width,
        height: props.height,
      }}
      bodyStyle={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
        height: props.height - headerHeight,
      }}
      title={
        <WidgetHeader
          text={t("dashboard.widgets.certifications.title")}
          onClickRemoveButton={props.onClickRemoveButton}
          isDraggable
        />
      }
    >
      <FlexHeader>
        {nonExpiredCertifications.filter((cert) => isAboutToExpire(cert))
          .length > 0 && (
          <Badge
            status="warning"
            text={t("dashboard.widgets.certifications.expires-soon")}
          />
        )}
      </FlexHeader>
      <CertificationList>
        {nonExpiredCertifications.map((certification) => (
          <CertificationListItem
            key={certification.id}
            isAboutToExpire={isAboutToExpire(certification)}
          >
            <Popover
              title={
                certification.note
                  ? getCertificationLabel(certification)
                  : undefined
              }
              content={
                certification.note ? (
                  <div>{certification.note}</div>
                ) : (
                  getCertificationLabel(certification)
                )
              }
            >
              <div>
                <BuildingCertificationBadge
                  certification={certification}
                  size={118}
                  title={getCertificationLabel(certification)}
                />
              </div>
            </Popover>
          </CertificationListItem>
        ))}
      </CertificationList>
    </DraggableCard>
  );
}
