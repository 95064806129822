import { createBrowserRouter, Outlet } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { ComponentsDetails } from "@/pages/Components/details/ComponentsDetails";
import { ComponentsPage } from "@/pages/Components/ComponentsPage";
import { WorkOrders } from "@/pages/workOrders/WorkOrders";
import { Viewer } from "@/pages/autodeskViewer/Viewer";
import { Upload } from "@/pages/autodeskViewer/Upload";
import { WaitForUrn } from "@/pages/autodeskViewer/WaitForUrn";
import { Error } from "@/pages/Error/Error";
import OrgAlarmsPage from "@/pages/org/OrgAlarmsPage";
import { ErrorResult } from "@/pages/Error/ErrorResult";
import { Root } from "@/pages/Root/Root";
import { MapPage } from "@/pages/portfolio/MapPage/MapPage";
import { ProperateCalendarPage } from "@/pages/properateCalendar/ProperateCalendarPage";
import { LastCalendarMode } from "@/pages/properateCalendar/LastCalendarMode";
import { TimeseriesPage } from "@/pages/Timeseries/TimeseriesPage";
import { PageAnalysisList } from "@/pages/AnalysisList";
import { PageAnalysisDetails } from "@/pages/AnalysisDetails";
import { BuildingOverview } from "@/pages/BuildingOverview/BuildingOverview";
import { Files } from "@/pages/fileType";
import { Status } from "@/pages/status";
import Portfolio from "@/pages/portfolio";
import BuildingSettings from "@/pages/buildingSettings";
import Sd from "@/pages/sd";
import Vnc from "@/pages/vnc";
import { CalculationFlowEditor } from "@/pages/calculationFlow/components/CalculationFlowEditor";
import { CalculationFlowTable } from "@/pages/calculationFlow/components/CalculationFlowTable";
import Admin from "@/pages/admin";
import { Alarms } from "@/pages/alarms/Alarms";
import IndoorClimate from "@/pages/indoorClimate";
import Writable from "@/pages/writable";
import Reports from "@/pages/reports";
import TechnicalSchemaView from "@/pages/techincalSchemaView";
import TechnicalSchemaList from "@/pages/technicalSchema";
import FloorPlan from "@/pages/floorPlan";
import FloorPlanView from "@/pages/floorPlanView";
import { Waste } from "@/pages/waste/Waste";
import Tasks from "@/pages/org/Tasks";
import {
  TaskManagerCreateTaskPage,
  TaskManagerCreateTaskTemplatePage,
  TaskManagerTaskFilePreviewPage,
  TaskManagerTaskPage,
  TaskManagerTaskTemplatePage,
} from "@/features/task-manager";
import { GaugeList } from "@/pages/GaugeList/GaugeList";
import { GaugeNew } from "@/pages/GaugeNew/GaugeNew";
import { GaugePage } from "@/pages/Gauge/GaugePage";
import { GaugeImport } from "@/pages/GaugeImport/GaugeImport";
import { AnalysisImport } from "@/pages/AnalysisImport/AnalysisImport";
import { Energy } from "@/pages/energy/Energy";
import { EnergyCompare } from "@/pages/energy/EnergyCompare";
import { Flow } from "@/pages/energy/Flow";
import { BuildingLayout } from "@/layout/BuildingLayout/BuildingLayout";
import { HeatMapListPage } from "@/pages/HeatMapList/HeatMapListPage";
import { HeatMapPage } from "@/pages/HeatMap/HeatMapPage";
import { HeatMapImport } from "@/pages/HeatMapImport/HeatMapImport";
import { AlarmUnsubscribe } from "@/pages/alarms/AlarmUnsubscribe";
import TaskTemplates from "@/pages/org/TaskTemplates";
import { OrganizationAdmin } from "src/pages/organizationAdmin";
import { OrganizationAdminOrganizationModules } from "@/pages/organizationAdmin/OrganizationAdminOrganizationModules";
import { OrganizationAdminBuildingModules } from "@/pages/organizationAdmin/OrganizationAdminBuildingModules";
import { ProtectedRouteByBuildingModulesAccess } from "@/routes/ProtectedRouteByBuildingModulesAccess";
import { AccessModules } from "@/features/organizationAdmin";
import { ProtectedRouteByOrganizationModulesAccess } from "@/routes/ProtectedRouteByOrganizationModulesAccess";
import { FilePreviewPage } from "@/pages/fileType/FilePreviewPage";
import { ComponentFilePreviewPage } from "@/pages/Components/details/ComponentFilePreviewPage";
import { FilesError } from "@/pages/fileType/FilesError";
import {
  AlarmNotificationsPage,
  AlarmSettingsPage,
  AlarmDeviationsPage,
  AlarmDetailsPage,
} from "@/pages/alarms";
import { NewAlarms } from "@/pages/alarms/NewAlarms";
import { AlarmDetailsPageId } from "@/pages/alarms/details/hooks/createNewAlarmHooks";
import { FloorPlan as FloorPlanV2 } from "@/pages/floorPlan/v2/FloorPlan";
import { FloorPlanList } from "@/pages/floorPlan/v2/FloorPlanList";
import {
  actionAnalyses,
  actionGauges,
  actionHeatMaps,
  actionUpdateAnalysis,
  actionUpdateGauge,
  actionUpdateHeatMap,
} from "./actions";
import {
  actionFiles,
  actionFilesPreview,
  initializeEnergyFlow,
  initializeEnergyHierarchy,
  initializeEnergyHierarchyCompare,
  initializeSourceEnergyFlow,
  loaderAdmin,
  loaderAnalyses,
  loaderAnalysis,
  loaderBuilding,
  loaderComponent,
  loaderComponentPreviewFile,
  loaderDashboard,
  loaderEnergy,
  loaderEnergyCompare,
  loaderEnergyFlow,
  loaderEnergySourceFlow,
  loaderFiles,
  loaderFilesPreview,
  loaderGauge,
  loaderGauges,
  loaderHeatMap,
  loaderHeatMaps,
  loaderReports,
  loaderRoot,
  loaderStatus,
  loaderWaste,
} from "./loaders";
import { RequiresBuildingMembership } from "./RequiresBuildingMembership";
import { RequiresAdminRole, RequiresSuperAdminRole } from "./RequiresAdminRole";
import { RedirectToImportIfNeeded } from "./RedirectToImportIfNeeded";

const queryClient = new QueryClient();

export const router: ReturnType<typeof createBrowserRouter> =
  createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      id: "root",
      loader: loaderRoot,
      errorElement: <Error />,
      children: [
        {
          index: true,
          element: <Portfolio />,
        },
        {
          path: "/status",
          element: <Status />,
          loader: loaderStatus,
        },
        {
          path: "/map",
          element: <MapPage />,
          children: [
            {
              path: ":buildingId",
              element: (
                <RequiresBuildingMembership>
                  <MapPage />
                </RequiresBuildingMembership>
              ),
            },
          ],
        },
        {
          path: "alarms/:alarmId/unsubscribe/email/:email",
          element: <AlarmUnsubscribe />,
        },
        {
          path: "alarms/:alarmId/unsubscribe/phone/:phone",
          element: <AlarmUnsubscribe />,
        },
        {
          path: "/org/alarms",
          element: (
            <ProtectedRouteByOrganizationModulesAccess
              accessModule={AccessModules.alarms}
            >
              <OrgAlarmsPage />
            </ProtectedRouteByOrganizationModulesAccess>
          ),
        },
        {
          path: "/org/tasks",
          element: (
            <ProtectedRouteByOrganizationModulesAccess
              accessModule={AccessModules.taskManagement}
            >
              <Tasks />
            </ProtectedRouteByOrganizationModulesAccess>
          ),
        },
        {
          path: "/org/task-templates",
          element: (
            <ProtectedRouteByOrganizationModulesAccess
              accessModule={AccessModules.taskManagement}
            >
              <TaskTemplates />
            </ProtectedRouteByOrganizationModulesAccess>
          ),
        },
        {
          path: "/org/task-templates/:taskTemplateId",
          element: (
            <ProtectedRouteByOrganizationModulesAccess
              accessModule={AccessModules.taskManagement}
            >
              <TaskManagerTaskTemplatePage fullLayout />
            </ProtectedRouteByOrganizationModulesAccess>
          ),
        },
        {
          path: "/org/tasks/create",
          element: (
            <ProtectedRouteByOrganizationModulesAccess
              accessModule={AccessModules.taskManagement}
            >
              <TaskManagerCreateTaskPage />
            </ProtectedRouteByOrganizationModulesAccess>
          ),
        },
        {
          path: "/org/task-templates/create",
          element: (
            <ProtectedRouteByOrganizationModulesAccess
              accessModule={AccessModules.taskManagement}
            >
              <TaskManagerCreateTaskTemplatePage />
            </ProtectedRouteByOrganizationModulesAccess>
          ),
        },
        {
          path: "/wait/:fileId",
          element: <WaitForUrn />,
        },
        {
          path: "/view/:urn",
          element: <Viewer />,
        },
        {
          path: "/view/upload/:urn",
          element: <Upload />,
        },
        {
          path: "asset/:id",
          id: "building",
          element: (
            <RequiresBuildingMembership>
              <BuildingLayout>
                <Outlet />
              </BuildingLayout>
            </RequiresBuildingMembership>
          ),
          loader: loaderBuilding,
          children: [
            {
              path: "building",
              element: <BuildingOverview />,
              loader: loaderDashboard,
            },
            {
              path: "energyConsumption",
              loader: initializeEnergyHierarchy,
            },
            {
              path: "energyCompare",
              loader: initializeEnergyHierarchyCompare,
            },
            {
              path: "energyFlow",
              loader: initializeEnergyFlow,
            },
            {
              path: "energySourceFlow",
              loader: initializeSourceEnergyFlow,
            },
            {
              path: "energyConsumption/:type/:granularity/s/:start/e/:end",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.energy}
                >
                  <Energy />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderEnergy,
            },
            {
              path: "energyConsumption/:type/:granularity/s/:start/e/:end/table",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.energy}
                >
                  <Energy table />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderEnergy,
            },
            {
              path: "energyCompare/:type/:granularity/s/:start/e/:end/s/:startNext/e/:endNext",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.energy}
                >
                  <EnergyCompare />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderEnergyCompare,
            },
            {
              path: "energyCompare/:type/:granularity/s/:start/e/:end/s/:startNext/e/:endNext/table",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.energy}
                >
                  <EnergyCompare table />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderEnergyCompare,
            },
            {
              path: "energyFlow/s/:start/e/:end",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.energy}
                >
                  <Flow />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderEnergyFlow,
            },
            {
              path: "energySourceFlow/s/:start/e/:end",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.energy}
                >
                  <Flow />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderEnergySourceFlow,
            },
            {
              path: "files/:search?",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.files}
                >
                  <Files />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderFiles(queryClient),
              action: actionFiles(queryClient),
              errorElement: <FilesError />,
            },
            {
              path: "trash/:search?",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.files}
                >
                  <Files hidden />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderFiles(queryClient, true),
              action: actionFiles(queryClient, true),
            },
            {
              path: "files/:search?/preview/:fileId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.files}
                >
                  <FilePreviewPage />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderFilesPreview(queryClient, false),
              action: actionFilesPreview(queryClient, false),
            },
            {
              path: "trash/:search?/preview/:fileId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.files}
                >
                  <FilePreviewPage />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderFilesPreview(queryClient, true),
              action: actionFilesPreview(queryClient, true),
            },
            {
              path: "settings",
              element: <BuildingSettings />,
            },
            {
              path: "vnc",
              element: <Vnc />,
            },
            {
              path: "sd",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.thirdParty}
                >
                  <Sd />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "timeseries",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.timeseries}
                >
                  <TimeseriesPage />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "components/:componentId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.components}
                >
                  <ComponentsDetails />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderComponent,
            },
            {
              path: "components/:componentId/preview/:fileId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.components}
                >
                  <ComponentFilePreviewPage />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderComponentPreviewFile,
            },
            {
              path: "components",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.components}
                >
                  <ComponentsPage />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "calendar/:mode",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.calendar}
                >
                  <ProperateCalendarPage />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "calendar",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.calendar}
                >
                  <LastCalendarMode />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "virtualSensor",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.virtualSensors}
                >
                  <CalculationFlowTable
                    key="virtualSensor"
                    flowType="virtualSensor"
                  />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "virtualSensor/:calculationFlowId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.virtualSensors}
                >
                  <CalculationFlowEditor flowType="virtualSensor" />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "cloudAutomation",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.cloudAutomation}
                >
                  <CalculationFlowTable
                    key="cloudAutomation"
                    flowType="cloudAutomation"
                  />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "cloudAutomation/:calculationFlowId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.cloudAutomation}
                >
                  <CalculationFlowEditor flowType="cloudAutomation" />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "floor-plan",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.roomManagement}
                >
                  <FloorPlanList />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "floor-plan/:snapshotId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.roomManagement}
                >
                  <FloorPlanV2 />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "floorPlan",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.roomManagement}
                >
                  <FloorPlan />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "floorPlan/:fileId/:view",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.roomManagement}
                >
                  <FloorPlanView />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "indoorClimate",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.indoorClimate}
                >
                  <IndoorClimate />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "analysis/simple",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <PageAnalysisList />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderAnalyses,
              action: actionAnalyses,
            },
            {
              path: "analysis/scatterplot",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <PageAnalysisList />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderAnalyses,
              action: actionAnalyses,
            },
            // react-router@6 does not support regex in path, hence we create 2 routes with the same element
            // See https://github.com/remix-run/react-router/discussions/9844 for details
            {
              path: "analysis/simple/:snapshotId/import",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <AnalysisImport />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderAnalysis,
            },
            {
              path: "analysis/scatterplot/:snapshotId/import",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <AnalysisImport />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderAnalysis,
            },
            {
              path: "analysis/simple/:snapshotId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <RedirectToImportIfNeeded>
                    <PageAnalysisDetails />
                  </RedirectToImportIfNeeded>
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderAnalysis,
              action: actionUpdateAnalysis,
            },
            {
              path: "analysis/scatterplot/:snapshotId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <RedirectToImportIfNeeded>
                    <PageAnalysisDetails />
                  </RedirectToImportIfNeeded>
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderAnalysis,
              action: actionUpdateAnalysis,
            },
            {
              path: "analysis/gauge",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <GaugeList />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderGauges,
              action: actionGauges,
            },
            {
              path: "analysis/gauge/new",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <GaugeNew />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "analysis/gauge/:snapshotId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <RedirectToImportIfNeeded>
                    <GaugePage />
                  </RedirectToImportIfNeeded>
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderGauge,
              action: actionUpdateGauge,
            },
            {
              path: "analysis/gauge/:snapshotId/import",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <GaugeImport />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderGauge,
            },
            {
              path: "analysis/heat-map",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <HeatMapListPage />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderHeatMaps,
              action: actionHeatMaps,
            },
            {
              path: "analysis/heat-map/:snapshotId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <RedirectToImportIfNeeded>
                    <HeatMapPage />
                  </RedirectToImportIfNeeded>
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderHeatMap,
              action: actionUpdateHeatMap,
            },
            {
              path: "analysis/heat-map/:snapshotId/import",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <HeatMapImport />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderHeatMap,
            },
            {
              path: "analysis/simple/:snapshotId/share",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <AnalysisImport />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderAnalysis,
            },
            {
              path: "analysis/scatterplot/:snapshotId/share",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.analysis}
                >
                  <AnalysisImport />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderAnalysis,
            },
            {
              path: "waste",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.waste}
                >
                  <Waste />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderWaste,
            },
            {
              path: "waste/:startDate/:endDate",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.waste}
                >
                  <Waste />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderWaste,
            },
            {
              path: "technicalSchema",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.technicalSchema}
                >
                  <TechnicalSchemaList />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "technicalSchema/:fileId/:view",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.technicalSchema}
                >
                  <TechnicalSchemaView />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "writable",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.setPoints}
                >
                  <Writable />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "alarms/:alarmId?",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: (
                    <ProtectedRouteByBuildingModulesAccess
                      accessModule={AccessModules.alarms}
                    >
                      <Alarms />
                    </ProtectedRouteByBuildingModulesAccess>
                  ),
                },
              ],
            },
            {
              path: "newAlarms",
              element: <NewAlarms />,
            },
            {
              path: "newAlarms/:alarmId",
              element: <AlarmDetailsPage />,
              id: "alarmDetails",
              children: [
                {
                  path: "settings",
                  id: AlarmDetailsPageId.Settings,
                  element: <AlarmSettingsPage />,
                },
                {
                  path: "notifications",
                  id: AlarmDetailsPageId.Notifications,
                  element: <AlarmNotificationsPage />,
                },
                {
                  path: "",
                  id: AlarmDetailsPageId.Deviations,
                  element: <AlarmDeviationsPage />,
                },
              ],
            },
            {
              path: "reports",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={[
                    AccessModules.waste,
                    AccessModules.energy,
                    AccessModules.indoorClimate,
                  ]}
                >
                  <Reports />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderReports,
            },
            {
              path: "reports/:reportToGenerate",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={[
                    AccessModules.waste,
                    AccessModules.energy,
                    AccessModules.indoorClimate,
                  ]}
                >
                  <Reports />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderReports,
            },
            {
              path: "reports/:reportToGenerate/:startDate/:endDate",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={[
                    AccessModules.waste,
                    AccessModules.energy,
                    AccessModules.indoorClimate,
                  ]}
                >
                  <Reports />
                </ProtectedRouteByBuildingModulesAccess>
              ),
              loader: loaderReports,
            },
            {
              path: "workOrders",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.taskManagement}
                >
                  <WorkOrders />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "workOrders/:taskId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.taskManagement}
                >
                  <TaskManagerTaskPage />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "workOrders/:taskId/files/:fileId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.taskManagement}
                >
                  <TaskManagerTaskFilePreviewPage />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "workOrders/create",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.taskManagement}
                >
                  <TaskManagerCreateTaskPage isInsideBuildingLayout />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "workOrders/create-template",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.taskManagement}
                >
                  <TaskManagerCreateTaskTemplatePage isInsideBuildingLayout />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "workOrders/template/:taskTemplateId",
              element: (
                <ProtectedRouteByBuildingModulesAccess
                  accessModule={AccessModules.taskManagement}
                >
                  <TaskManagerTaskTemplatePage />
                </ProtectedRouteByBuildingModulesAccess>
              ),
            },
            {
              path: "admin",
              element: (
                <RequiresAdminRole>
                  <Admin />
                </RequiresAdminRole>
              ),
              loader: loaderAdmin,
            },
            {
              path: "organization-admin",
              element: (
                <RequiresSuperAdminRole>
                  <OrganizationAdmin />
                </RequiresSuperAdminRole>
              ),
            },
            {
              path: "organization-admin/organization-modules/:organizationId",
              element: (
                <RequiresSuperAdminRole>
                  <OrganizationAdminOrganizationModules />
                </RequiresSuperAdminRole>
              ),
            },
            {
              path: "organization-admin/building-modules/:dataSetId",
              element: (
                <RequiresSuperAdminRole>
                  <OrganizationAdminBuildingModules />
                </RequiresSuperAdminRole>
              ),
            },
          ],
        },
        {
          path: "*",
          element: <ErrorResult status={404} />,
        },
      ],
    },
  ]);
