/* eslint-disable react/jsx-no-literals */
import { browserFirestore } from "@properate/firebase";
import * as Sentry from "@sentry/react";
import { Button, message } from "antd";
import { addDoc, collection } from "firebase/firestore";
import { useEffect, useState } from "react";

let enabled = false;
const breadcrumbs: Sentry.Breadcrumb[] = [];

export const debugging = {
  enable() {
    enabled = true;
  },
  disable() {
    enabled = false;
  },
  async record(message: string, data?: Record<string, unknown>) {
    if (!enabled) {
      return;
    }

    let snapshotId = "";

    try {
      const doc = await addDoc(collection(browserFirestore, "debug"), {
        timestamp: Date.now(),
        message,
        data,
      });

      snapshotId = doc.id;
    } catch (error) {
      console.error("Error recording debugging message on Firestore", error);
    }

    breadcrumbs.push({
      message,
      timestamp: Date.now(),
      data: {
        debug_snapshot_id: snapshotId,
        ...data,
      },
      category: "debugging",
      level: "debug",
    });
  },
  publish() {
    if (!enabled) {
      return;
    }

    Sentry.captureEvent({
      breadcrumbs: breadcrumbs,
      message: "Debugging session",
    });
  },
};

export function useDebugging() {
  useEffect(() => {
    debugging.enable();

    return () => {
      debugging.disable();
    };
  }, []);
}

export function useDebuggedState<T>(name: string, initialValue: T | (() => T)) {
  const [state, setState] = useState<T>(initialValue);

  useEffect(() => {
    debugging.record(name, { state });
  }, [name, state]);

  return [state, setState] as const;
}

export function DebugPublisher() {
  function handleSubmit() {
    debugging.publish();
    message.success("Report published");
  }

  return (
    <Button
      style={{ position: "absolute", right: 16, bottom: 64 }}
      onClick={handleSubmit}
    >
      Report Problem
    </Button>
  );
}
