import { useNavigate } from "react-router-dom";
import { WithSnapshotId } from "@properate/common";
import { HeatMap, HeatMapComponent } from "@/features/heatmap";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { getCurrentStartOfWeekMonthDateOrReferenceDate } from "@/pages/common/utils";
import { DraggableCard } from "../draggable/DraggableCard";
import { WidgetHeader } from "../widget-components/WidgetHeader";

interface Props {
  heatMap: WithSnapshotId<HeatMap>;
  width: number;
  height: number;
  onClickRemoveButton: () => unknown;
}

export function HeatMapWidget({
  heatMap,
  width,
  height,
  onClickRemoveButton,
}: Props) {
  const currentBuildingId = useCurrentBuildingId();
  const navigate = useNavigate();
  const headerHeight = 46;
  const bodyPadding = 12;
  return (
    <DraggableCard
      style={{ width, height }}
      bordered={false}
      title={
        <WidgetHeader
          isDraggable
          text={heatMap.name}
          onClickRemoveButton={onClickRemoveButton}
          onClickSettingsButton={() =>
            navigate(
              `/asset/${currentBuildingId}/analysis/heat-map/${heatMap.snapshotId}`,
            )
          }
        />
      }
      bodyStyle={{
        display: "grid",
        placeContent: "center",
        height: `calc(100% - ${headerHeight}px)`,
        padding: bodyPadding,
      }}
    >
      <HeatMapComponent
        name={heatMap.name}
        aggregate={heatMap.aggregate}
        color={heatMap.color}
        timeseriesId={heatMap.timeseriesId}
        referenceDate={getCurrentStartOfWeekMonthDateOrReferenceDate(
          heatMap.viewMode,
          heatMap.referenceDate,
          heatMap.isCurrentStartOfWeekMonthChecked,
        )}
        isCurrentStartOfWeekMonthChecked={
          heatMap.isCurrentStartOfWeekMonthChecked
        }
        showWeekNumbers={heatMap.showWeekNumbers}
        viewMode={heatMap.viewMode}
        width={width - bodyPadding * 2}
        height={height - headerHeight}
        compact
      />
    </DraggableCard>
  );
}
