import { fetchLoginStatus } from "@properate/api";
import { browserFirestore } from "@properate/firebase";
import { Task } from "@properate/task-manager";
import {
  collection,
  CollectionReference,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import {
  ActionFunctionArgs,
  defer,
  json,
  LoaderFunctionArgs,
  redirect,
} from "react-router-dom";
import {
  Aggregate,
  Asset,
  Timeseries,
  DatapointAggregates,
  Datapoints,
  DatapointAggregate,
  DocumentFilter,
  DocumentSearchItem,
  FileUploadResponse,
} from "@cognite/sdk";
import {
  fileExtensionsForAutodeskViewer,
  formatSubBuildingFromExternalId,
  getComponentFromExternalId,
  getSystemCodeFromExternalId,
  isOperationalDay,
  isOperationalHour,
  OperationalHoursType,
  translateComponentType,
} from "@properate/common";
import dayjs from "@properate/dayjs";
import { HolidaysTypes } from "date-holidays";
import { notification, UploadFile } from "antd";
import { QueryClient } from "@tanstack/react-query";
import { ProperateFile } from "@properate/file-viewer/src/components/types";
import axios from "axios";
import { getFileUrl, getIcon } from "@/utils/cdf";
import { componentsIndex } from "@/eepApi";
import keycloak, { isAdmin } from "@/keycloak";
import { Analysis, isScatterplotAnalysis } from "@/features/analysis";
import { Gauge } from "@/features/gauges";
import { cogniteClient } from "@/services/cognite-client";
import {
  getDatapointsHoursRaw,
  retrieveAggregateOfDatapointsInPeriod,
} from "@/routes/energy/api";
import {
  DESCRIPTION_LEGEND_VALUES,
  getHolidayPeriods,
  getNonOperationalPeriods,
} from "@/features/energy";
import hd from "@/utils/holidays";
import {
  getBuildingAssets,
  getClosestDay,
  getIndoorClimateAvailableSensors,
  getTimeseriesForAssets,
  getTimeseriesWithLabels,
  Granularity,
  mergeDatapoints,
  mergeTimeseries,
} from "@/utils/helpers";
import { HeatMap } from "@/features/heatmap";
import { getStartAndEndOfTheMonthDates } from "@/utils/date";
import { Sort, UpdateFile } from "@/pages/fileType/types";
import { getUrl } from "@/pages/fileType/utils";
import { triggerGetUrn } from "@/pages/autodeskViewer/Viewer-helpers";
import { isErrorWithMessage } from "@/utils/api";

const collectionAnalysis = collection(
  browserFirestore,
  "analysis",
) as CollectionReference<Analysis>;
const collectionGauge = collection(
  browserFirestore,
  "gauge",
) as CollectionReference<Gauge>;
const collectionHeatMap = collection(
  browserFirestore,
  "heatMap",
) as CollectionReference<HeatMap>;

const collectionStatus = collection(
  browserFirestore,
  "status",
) as CollectionReference<{ test: string }>;

async function loadDocument<T extends { owner: string }>(
  collection: CollectionReference<T>,
  snapshotId?: string,
): Promise<T> {
  let documentSnapshot: DocumentSnapshot<T>;
  try {
    documentSnapshot = await getDoc(doc(collection, snapshotId));
  } catch (error) {
    throw json({ error });
  }
  if (!documentSnapshot.exists()) {
    throw json(null, 404);
  }
  return documentSnapshot.data();
}

function isVisibleBuilding(asset: Asset) {
  const isVisible = asset.labels
    ? asset.labels.every((label) => label.externalId !== "hidden")
    : true;
  if (asset.externalId) {
    const isBuilding =
      asset.externalId.startsWith("AH_") &&
      !asset.externalId.startsWith("AH_0000");
    return isBuilding && isVisible;
  }
  return false;
}

async function getRootAssets() {
  const { items: assets } = await cogniteClient.assets.list({
    filter: { root: true },
    limit: 1000,
  });

  return assets.filter(isVisibleBuilding);
}

export async function loaderRoot() {
  // Client may not have authenticated yet at this point
  await cogniteClient.authenticate();
  return getRootAssets();
}

export async function loaderStatus() {
  await cogniteClient.authenticate();

  const loginStatus = fetchLoginStatus();

  const assets = getRootAssets();

  const firestore = getDoc(doc(collectionStatus, "test"));

  return { assets, loginStatus, firestore };
}

export async function loaderBuilding({ params: { id } }: LoaderFunctionArgs) {
  // Client may not have authenticated yet at this point
  await cogniteClient.authenticate();
  const rootAssets = await getRootAssets();
  const rootAsset = rootAssets.find((rootAsset) => rootAsset.id === Number(id));
  if (!rootAsset) {
    throw json(null, 404);
  }
  return rootAsset;
}

const buildingsCollection = collection(browserFirestore, "buildings");
const isOperational = (
  timestamp: number,
  operationalHours: OperationalHoursType[],
  granularity: Granularity,
) => {
  if (granularity === "h") {
    return isOperationalHour(dayjs(timestamp), operationalHours, false)
      ? DESCRIPTION_LEGEND_VALUES.operational
      : DESCRIPTION_LEGEND_VALUES.nonOperational;
  }
  if (granularity === "d") {
    return isOperationalDay(dayjs(timestamp).isoWeekday() - 1, operationalHours)
      ? DESCRIPTION_LEGEND_VALUES.operational
      : DESCRIPTION_LEGEND_VALUES.nonOperational;
  }
  return DESCRIPTION_LEGEND_VALUES.operational;
};

function getHolidays(
  startDate: Date,
  endDate: Date,
): Record<number, HolidaysTypes.Holiday> {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const diffYears = end.diff(start, "year");
  const years = [];

  for (let i = 0; i <= diffYears; i++) {
    years.push(start.year() + i);
  }
  return years
    .map((year) => hd.getHolidays(year))
    .flat()
    .reduce(
      (acc, curr) => ({
        ...acc,
        [dayjs(curr.start).startOf("day").valueOf()]: curr,
      }),
      {},
    );
}

const hasEPredForSensors = async (ids: number[]) => {
  if (ids.length === 0) {
    return false;
  }
  const timeseries = await cogniteClient.timeseries.retrieve(
    ids.map((id) => ({
      id,
    })),
  );
  const epredAssets = await cogniteClient.assets
    .list({
      filter: {
        parentIds: timeseries.map((ts) => ts.assetId!),
        labels: {
          containsAll: [{ externalId: "epred" }],
        },
      },
    })
    .autoPagingToArray({ limit: Infinity });

  return epredAssets.length === ids.length * 3;
};
const getEPredDataForSensorsAggregated = async (
  ids: number[],
  start: Date,
  end: Date,
  granularity: Granularity,
) => {
  const timeseries = await cogniteClient.timeseries.retrieve(
    ids.map((id) => ({
      id,
    })),
  );
  const epredAssets = await cogniteClient.assets
    .list({
      filter: {
        parentIds: timeseries.map((ts) => ts.assetId!),
        labels: {
          containsAll: [{ externalId: "epred" }],
        },
      },
    })
    .autoPagingToArray({ limit: Infinity });

  if (epredAssets.length < ids.length * 3) {
    return [];
  }

  const epredTimeseries = (
    await cogniteClient.timeseries
      .list({
        filter: {
          assetIds: epredAssets.map((asset) => asset.id),
        },
      })
      .autoPagingToArray({ limit: Infinity })
  ).filter((ts) => ts.externalId!.startsWith("TS"));
  // get ePred and max and min for each sensor
  const result = timeseries.reduce<
    { ePred?: Timeseries; ePredMax?: Timeseries; ePredMin?: Timeseries }[]
  >(
    (prev, current) => [
      ...prev,
      {
        ePred: epredTimeseries.find(
          (ts) => ts.name === `${current.name} Epred-001`,
        ),
        ePredMax: epredTimeseries.find(
          (ts) => ts.name === `${current.name} Epred-001-RMSE-max`,
        ),
        ePredMin: epredTimeseries.find(
          (ts) => ts.name === `${current.name} Epred-001-RMSE-min`,
        ),
      },
    ],
    [],
  );

  if (
    !result.reduce(
      (prev, current) =>
        prev &&
        current.ePred !== undefined &&
        current.ePredMax !== undefined &&
        current.ePredMin !== undefined,
      true,
    )
  ) {
    return [];
  }

  const ePredPoints = await (granularity === "h"
    ? getDatapointsHoursRaw({
        ids: result.flatMap((node) => [
          node.ePred!.id,
          node.ePredMax!.id,
          node.ePredMin!.id,
        ]),
        start,
        end,
        aggregate: "sum",
      })
    : retrieveAggregateOfDatapointsInPeriod({
        ids: result.flatMap((node) => [
          node.ePred!.id,
          node.ePredMax!.id,
          node.ePredMin!.id,
        ]),
        start,
        end,
        granularity,
        aggregate: "sum",
      }));

  type ePredData = {
    timestamp: number;
    ePred: number;
    ePredMax: number;
    ePredMin: number;
  };

  return result.reduce<ePredData[]>((prev, current) => {
    const ePred = ePredPoints
      .find((point) => point.id === current.ePred!.id)
      ?.datapoints?.map((dp) => ({ timestamp: dp.timestamp, ePred: dp.value }));
    const ePredMin = ePredPoints
      .find((point) => point.id === current.ePredMin!.id)
      ?.datapoints?.map((dp) => ({
        timestamp: dp.timestamp,
        ePredMin: dp.value,
      }));
    const ePredMax = ePredPoints
      .find((point) => point.id === current.ePredMax!.id)
      ?.datapoints?.map((dp) => ({
        timestamp: dp.timestamp,
        ePredMax: dp.value,
      }));

    if (!ePred || !ePredMax || !ePredMin) {
      return prev;
    }

    const currentPoints = mergeTimeseries(
      mergeTimeseries(ePred, ePredMin),
      ePredMax,
    ) as ePredData[];

    if (prev.length > 0) {
      return prev.map((point, index) => ({
        timestamp: point.timestamp,
        ePred: point.ePred + currentPoints[index].ePred,
        ePredMax: point.ePredMax + currentPoints[index].ePredMax,
        ePredMin: point.ePredMin + currentPoints[index].ePredMin,
      }));
    }
    return currentPoints;
  }, []);
};
const hasEPredForHierarchy = async (rootId: number | null) => {
  if (!rootId) {
    return false;
  }
  const epredAssets = await cogniteClient.assets
    .list({
      filter: {
        parentIds: [rootId],
        labels: {
          containsAll: [{ externalId: "epred" }],
        },
      },
    })
    .autoPagingToArray({ limit: Infinity });

  return epredAssets.length > 0;
};
const getEPredDataForHierarchyAggregated = async (
  rootId: number | null,
  start: Date,
  end: Date,
  granularity: Granularity,
) => {
  if (!rootId) {
    return [];
  }
  const epredAssets = await cogniteClient.assets
    .list({
      filter: {
        parentIds: [Number(rootId)],
        labels: {
          containsAll: [{ externalId: "epred" }],
        },
      },
    })
    .autoPagingToArray({ limit: Infinity });

  const epredTimeseries =
    epredAssets.length === 0
      ? []
      : (
          await cogniteClient.timeseries
            .list({
              filter: {
                assetIds: epredAssets.map((asset) => asset.id),
              },
            })
            .autoPagingToArray({ limit: Infinity })
        ).filter((ts) => ts.externalId!.startsWith("TS"));

  if (epredTimeseries.length === 0) {
    return [];
  }

  const ePred = epredTimeseries.find((ts) => ts.name?.endsWith("001"));
  const ePredMax = epredTimeseries.find((ts) => ts.name?.endsWith("max"));
  const ePredMin = epredTimeseries.find((ts) => ts.name?.endsWith("min"));

  if (!ePred || !ePredMax || !ePredMin) {
    return [];
  }

  const ePredPoints = await (granularity === "h"
    ? getDatapointsHoursRaw({
        ids: [ePred.id, ePredMax.id, ePredMin.id],
        start,
        end,
        aggregate: "sum",
      })
    : retrieveAggregateOfDatapointsInPeriod({
        ids: [ePred.id, ePredMax.id, ePredMin.id],
        start,
        end,
        granularity,
        aggregate: "sum",
      }));

  return mergeTimeseries(
    mergeTimeseries(
      ePredPoints[0].datapoints.map((dp) => ({
        timestamp: dp.timestamp,
        ePred: dp.value,
      })),
      ePredPoints[1].datapoints.map((dp) => ({
        timestamp: dp.timestamp,
        ePredMax: dp.value,
      })),
    ),
    ePredPoints[2].datapoints.map((dp) => ({
      timestamp: dp.timestamp,
      ePredMin: dp.value,
    })),
  );
};

const parseExpression = (vars: Record<string, string>, expression?: string) => {
  if (!expression) return undefined;

  return expression.replace(/[A-Z]/g, (match) => ` ${vars[match]} `).trim();
};

const getTimeSeriesName = (ts: Timeseries) => {
  const description = ts.description?.replace(".", "") || "";
  const system = getSystemCodeFromExternalId(ts.externalId!);

  return ts.name
    ? `${system} ${description} (${ts.name})`
    : `${system} ${description}`;
};

const parseVars = (vars?: string) => {
  if (!vars) return {};
  const trimVar = (str: string) =>
    str.trim().replace(/^['"]/, "").replace(/['"]$/, "");

  try {
    return vars
      .replace("{", "")
      .replace("}", "")
      .split(",")
      .map((pair) => pair.split(":"))
      .reduce(
        (prev, [key, value]) => {
          prev[trimVar(key)] = trimVar(value);
          return prev;
        },
        {} as Record<string, string>,
      );
  } catch (e) {
    console.error(`Could not parse vars ${JSON.stringify(vars)}`, e);
    return {};
  }
};
const getLegend = async (ids: number[]) => {
  const timeseries = await cogniteClient.timeseries.retrieve(
    ids.map((id) => ({
      id,
    })),
  );

  return await Promise.all(
    timeseries.map(async (ts) => {
      const name = getTimeSeriesName(ts);

      const vars = parseVars(ts.metadata?.vars);

      const varToName = await Object.entries(vars).reduce<
        Promise<Record<string, string>>
      >(async (acc, [key, value]) => {
        const prev = await acc;
        const timeseries = await cogniteClient.timeseries.retrieve([
          { externalId: value },
        ]);
        prev[key] = getTimeSeriesName(timeseries[0]);
        return prev;
      }, Promise.resolve({}));
      const externalIdExpression = parseExpression(
        vars,
        ts.metadata?.expression,
      );

      const expression = parseExpression(varToName, ts.metadata?.expression);

      return {
        name,
        expression,
        externalIdExpression,
        externalId: ts.externalId,
        source:
          ts.metadata?.source !== "generated/oegen"
            ? ts.metadata?.source
            : undefined,
      };
    }),
  );
};

const getEnergyDataHoursRaw = async (ids: number[], start: Date, end: Date) => {
  const timeseries = await cogniteClient.timeseries.retrieve(
    ids.map((id) => ({
      id,
    })),
  );

  const datapointHours = await getDatapointsHoursRaw({
    ids: ids.map((id) => Number(id)),
    start: new Date(Number(start)),
    end: new Date(Number(end)),
    aggregate: "sum",
  });

  const empty = Array.from(
    { length: dayjs(end).diff(start, "hour") + 1 },
    (_, index) => ({
      timestamp: dayjs(start).add(index, "hour").toDate(),
      value: 0,
    }),
  );

  const datapoints = datapointHours.map((d) =>
    d.datapoints.length !== empty.length
      ? { ...d, datapoints: mergeDatapoints(d.datapoints, empty) }
      : d,
  );

  const legend = timeseries.map((ts) => getTimeSeriesName(ts));

  // combine datapoints
  return datapoints.reduce<Record<string, number>[]>((prev, current, index) => {
    const datapoints = current.datapoints.map((dp) => ({
      [legend[index]]: Math.max(dp.value || 0, 0),
      timestamp: dp.timestamp,
    }));
    if (prev.length === 0) {
      return datapoints;
    }

    return mergeTimeseries(prev, datapoints);
  }, []);
};

const getLatestDataPointTime = async (ids: number[], end: Date) => {
  const datapoints = await cogniteClient.datapoints.retrieveLatest(
    ids.map((id) => ({ id, before: end })),
  );
  return datapoints.reduce<Date | null>((prev, current) => {
    const latestCurrent =
      current.datapoints.length > 0
        ? current.datapoints[current.datapoints.length - 1].timestamp
        : null;

    if (latestCurrent === null) {
      return prev;
    }
    if (prev === null) {
      return latestCurrent;
    }
    return prev.valueOf() > latestCurrent.valueOf() ? prev : latestCurrent;
  }, null);
};

const getTemperatureDataAggregated = async (
  id: number,
  start: Date,
  end: Date,
  granularity: Granularity,
) => {
  const temperatureAssets = await cogniteClient.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id: Number(id) }],
        labels: {
          containsAll: [
            { externalId: "frost" },
            { externalId: "weather" },
            { externalId: "airtemperature" },
          ],
        },
      },
    })
    .autoPagingToArray({ limit: Infinity });

  const temperatureTimeseries =
    temperatureAssets.length === 0
      ? []
      : (
          await cogniteClient.timeseries
            .list({
              filter: {
                assetIds: temperatureAssets.map((asset) => asset.id),
              },
            })
            .autoPagingToArray({ limit: Infinity })
        ).filter((ts) => ts.externalId!.startsWith("TS"));

  return temperatureTimeseries.length === 0
    ? []
    : (
        await getDataAggregated(
          temperatureTimeseries.map((ts) => ts.id),
          start,
          end,
          granularity,
          "average",
        )
      ).map((point) => {
        const { timestamp, ...rest } = point;
        return {
          timestamp,
          value: point[Object.keys(rest)[0]],
        };
      });
};

const getDataAggregated = async (
  ids: number[],
  start: Date,
  end: Date,
  granularity: Granularity,
  aggregate: Aggregate,
) => {
  const timeseries = await cogniteClient.timeseries.retrieve(
    ids.map((id) => ({
      id,
    })),
  );

  const granularityStart = dayjs(start).startOf(granularity);

  const datapoints = await retrieveAggregateOfDatapointsInPeriod({
    ids: ids.map((id) => Number(id)),
    start: granularityStart.toDate(),
    end: end,
    granularity,
    aggregate,
  });

  const legend = timeseries.map((ts) => getTimeSeriesName(ts));

  const empty = Array.from(
    { length: dayjs(end).diff(start, granularity) + 1 },
    (_, index) => ({
      timestamp: granularityStart.add(index, granularity).toDate(),
      value: 0,
    }),
  );

  return datapoints.reduce<Record<string, number>[]>((prev, current, index) => {
    const c =
      current.datapoints.length !== empty.length
        ? { ...current, datapoints: mergeDatapoints(current.datapoints, empty) }
        : current;

    const datapoints = c.datapoints.map((dp) => ({
      [legend[index]]: dp.value,
      timestamp: dp.timestamp,
    }));
    if (prev.length === 0) {
      return datapoints;
    }

    return mergeTimeseries(prev, datapoints);
  }, []);
};
const getEnergyDataAggregated = async (
  ids: number[],
  start: Date,
  end: Date,
  granularity: Granularity,
) => {
  return getDataAggregated(ids, start, end, granularity, "sum");
};
type DeferredData = Record<string, any>;

const getDefaultRootForEnergyHierarchy = async (id: number) => {
  const assets = await cogniteClient.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id: Number(id) }],
        labels: { containsAll: [{ externalId: "oe_common_main_meter" }] },
      },
    })
    .autoPagingToArray({ limit: Infinity });

  const mainAssets = assets.filter((asset) =>
    getSystemCodeFromExternalId(asset.externalId!).endsWith(".001"),
  );

  if (mainAssets.length === 0) {
    return null;
  }

  return mainAssets[0].id;
};
const getDefaultTimeseriesForEnergyHierarchy = async (id: number) => {
  const assets = await cogniteClient.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id: Number(id) }],
        labels: { containsAll: [{ externalId: "oe_common_sub_serial_meter" }] },
      },
    })
    .autoPagingToArray({ limit: Infinity });

  const mainBuildingAssets = assets.filter((asset) =>
    getSystemCodeFromExternalId(asset.externalId!).endsWith(".001"),
  );

  if (mainBuildingAssets.length === 0) {
    return [];
  }

  const timeseries = (
    await cogniteClient.timeseries
      .list({
        filter: { assetIds: mainBuildingAssets.map((asset) => asset.id) },
      })
      .autoPagingToArray({ limit: Infinity })
  ).filter((ts) => ts.externalId!.startsWith("TS"));

  return timeseries.map((ts) => ts.id);
};
export async function initializeEnergyHierarchy({
  params: { id },
}: LoaderFunctionArgs) {
  const start = dayjs().subtract(2, "week").startOf("day").valueOf();

  const tenantRootAssetId = await getDefaultRootForEnergyHierarchy(Number(id));

  const ids = await getDefaultTimeseriesForEnergyHierarchy(Number(id));

  if (tenantRootAssetId === null) {
    redirect(`../energyConsumption/h/h/s/${start}/e/${dayjs().valueOf()}`);
  }

  return redirect(
    `../energyConsumption/h/h/s/${start}/e/${dayjs().valueOf()}?root=${tenantRootAssetId}&${ids
      .map((id) => `id=${id}`)
      .join("&")}`,
  );
}

export async function initializeEnergyHierarchyCompare({
  params: { id },
}: LoaderFunctionArgs) {
  const ids = await getDefaultTimeseriesForEnergyHierarchy(Number(id));

  const start = dayjs().subtract(2, "week").startOf("day").valueOf();
  const end = dayjs().valueOf();

  const compareToStart = getClosestDay(
    dayjs(start),
    dayjs(start).subtract(1, "y"),
  ).valueOf();
  const compareToEnd = dayjs(compareToStart)
    .add(dayjs(end).diff(start, "h"), "h")
    .valueOf();

  const tenantRootAssetId = await getDefaultRootForEnergyHierarchy(Number(id));

  if (tenantRootAssetId === null) {
    return redirect(
      `../energyCompare/h/h/s/${start}/e/${end}/s/${compareToStart}/e/${compareToEnd}`,
    );
  }

  return redirect(
    `../energyCompare/h/h/s/${start}/e/${end}/s/${compareToStart}/e/${compareToEnd}?root=${tenantRootAssetId}&${ids
      .map((id) => `id=${id}`)
      .join("&")}`,
  );
}

const addTotal = (d: Record<string, number>[]): Record<string, number>[] => {
  return d.map((current) => {
    const total = Object.entries(current)
      .filter(([key]) => key !== "timestamp")
      .reduce((prev, [_, current]) => prev + current, 0);
    return { ...current, total };
  });
};

const addOperational =
  (
    closedPublicHolidays: boolean,
    granularity: Granularity,
    holidays: any,
    operationalHours: OperationalHoursType[],
  ) =>
  (d: Record<string, number>[]) => {
    return d.map(
      (current) =>
        ({
          ...current,
          operational:
            (closedPublicHolidays &&
              (granularity === "h" || granularity === "d") &&
              holidays[dayjs(current.timestamp).startOf("day").valueOf()] &&
              DESCRIPTION_LEGEND_VALUES.holidays) ||
            isOperational(
              current.timestamp.valueOf(),
              operationalHours,
              granularity,
            ),
        }) as Record<string, number | string | undefined>,
    );
  };

const getType = (type?: string) => {
  if (type === "h") return "hierarchy";
  if (type === "i") return "energyMeter";
  if (type === "g") return "generated";
  if (type === "s") return "sources";

  return "hierarchy";
};

const getIds = async (url: string, id?: string, type?: string) => {
  const idsInUrl = new URL(url).searchParams.getAll("id");
  return idsInUrl.length > 0
    ? idsInUrl.map((id) => Number(id))
    : type === "h"
    ? await getDefaultTimeseriesForEnergyHierarchy(Number(id))
    : [];
};

export async function loaderEnergy({
  request,
  params: { granularity, start, end, id, type },
}: LoaderFunctionArgs): Promise<DeferredData> {
  const granularityStart = dayjs(Number(start))
    .startOf(granularity as Granularity)
    .toDate();
  const granularityEnd = dayjs(Number(end))
    .endOf(granularity as Granularity)
    .toDate();
  const ids = await getIds(request.url, id, type);
  const tenantRootAssetId = new URL(request.url).searchParams.get("root")
    ? Number(new URL(request.url).searchParams.get("root"))
    : await getDefaultRootForEnergyHierarchy(Number(id));

  const buildingRef = doc(buildingsCollection, String(id));
  const building = await getDoc(buildingRef);

  const holidays = getHolidays(granularityStart, granularityEnd);

  const temperature = getTemperatureDataAggregated(
    Number(id),
    granularityStart,
    granularityEnd,
    granularity as Granularity,
  );

  const epredFound =
    type === "h"
      ? tenantRootAssetId
        ? hasEPredForHierarchy(tenantRootAssetId)
        : false
      : hasEPredForSensors(ids.map((id) => Number(id)));

  const ePred =
    type === "h"
      ? getEPredDataForHierarchyAggregated(
          tenantRootAssetId,
          granularityStart,
          granularityEnd,
          granularity as Granularity,
        )
      : getEPredDataForSensorsAggregated(
          ids.map((id) => Number(id)),
          granularityStart,
          granularityEnd,
          granularity as Granularity,
        );

  const legend = ids.length === 0 ? [] : getLegend(ids.map((id) => Number(id)));

  const data: Promise<Record<string, number>[]> =
    ids.length === 0
      ? Promise.resolve([])
      : (granularity === "h" && type === "h"
          ? getEnergyDataHoursRaw(
              ids.map((id) => Number(id)),
              granularityStart,
              granularityEnd,
            )
          : getEnergyDataAggregated(
              ids.map((id) => Number(id)),
              granularityStart,
              granularityEnd,
              granularity as Granularity,
            )
        )
          .then(addTotal)
          .then(
            addOperational(
              building.data()!.closedPublicHolidays,
              granularity as Granularity,
              holidays,
              building.data()!.operationalHours,
            ),
          )
          .then(async (data) => {
            const energyPoints = data.map((point) => ({
              ...point,
              timestamp: point.timestamp?.valueOf(),
            }));
            const temperatureData = await temperature;
            const temperaturePoints = temperatureData.map((point) => ({
              temperature: point.value,
              timestamp: point.timestamp.valueOf(),
            }));
            return mergeTimeseries(energyPoints, temperaturePoints);
          })
          .then((data) => {
            return ePred.then((ePred) => {
              if (ePred.length === 0) return data;
              return mergeTimeseries(data, ePred);
            });
          });

  const latestDatapointDate =
    ids.length === 0
      ? null
      : getLatestDataPointTime(
          ids.map((id) => Number(id)),
          granularityEnd,
        );

  return defer({
    data: data, // promise
    start: granularityStart,
    end: granularityEnd,
    nonOperationalPeriods:
      granularity === "h" || granularity === "d"
        ? getNonOperationalPeriods(
            building.data()!.operationalHours,
            granularityStart,
            granularityEnd,
          )
        : [],
    holidayPeriods: getHolidayPeriods(
      granularity as Granularity,
      granularityStart,
      granularityEnd,
    ),
    weather: temperature,
    usableFloorArea: building.data()!.usableFloorArea, // promise
    assetId: String(id),
    closedPublicHolidays: building.data()?.closedPublicHolidays,
    granularity,
    selectedIds: ids.map((id) => Number(id)),
    type: getType(type),
    legend,
    tenantRootAssetId: tenantRootAssetId,
    hasEPred: epredFound,
    latestDatapointDate,
  } as DeferredData);
}

export async function loaderEnergyCompare({
  request,
  params: { start, end, id, startNext, endNext, granularity, type },
}: LoaderFunctionArgs): Promise<DeferredData> {
  const buildingRef = doc(buildingsCollection, String(id));
  const building = await getDoc(buildingRef);

  const ids = await getIds(request.url, id, type);
  const tenantRootAssetId = new URL(request.url).searchParams.get("root")
    ? Number(new URL(request.url).searchParams.get("root"))
    : await getDefaultRootForEnergyHierarchy(Number(id));

  const startDate = dayjs(Number(start))
    .startOf(granularity as Granularity)
    .toDate();
  const endDate = new Date(Number(end));
  const startNextDate = dayjs(Number(startNext))
    .startOf(granularity as Granularity)
    .toDate();
  const endNextDate = new Date(Number(endNext));

  const granularityStart = dayjs(startDate)
    .startOf(granularity as Granularity)
    .toDate();
  const granularityEnd = dayjs(endDate)
    .endOf(granularity as Granularity)
    .toDate();

  const granularityStartNext = dayjs(startNextDate)
    .startOf(granularity as Granularity)
    .toDate();
  const granularityStartEnd = dayjs(endNextDate)
    .startOf(granularity as Granularity)
    .add(1, granularity as Granularity)
    .toDate();

  const dayDiff = Math.round(
    dayjs(startDate).diff(dayjs(startNextDate), "day", true),
  );
  const weekDiff = Math.round(
    dayjs(startDate).diff(dayjs(startNextDate), "week", true),
  );

  const temperature = getTemperatureDataAggregated(
    Number(id),
    granularityStart,
    granularityEnd,
    granularity as Granularity,
  );

  const temperatureNext = getTemperatureDataAggregated(
    Number(id),
    granularityStartNext,
    granularityStartEnd,
    granularity as Granularity,
  );

  const nonOperationalPeriods = getNonOperationalPeriods(
    building.data()!.operationalHours,
    startDate,
    endDate,
  );

  const nonOperationalPeriodsNext = getNonOperationalPeriods(
    building.data()!.operationalHours,
    startNextDate,
    endNextDate,
  );

  const holidayPeriods = getHolidayPeriods(
    granularity as Granularity,
    startDate,
    endDate,
  );

  const holidayPeriodsNext = getHolidayPeriods(
    granularity as Granularity,
    startNextDate,
    endNextDate,
  );

  if (ids.length === 0) {
    return defer({
      data: Promise.resolve([]),
      start: startDate,
      end: endDate,
      startNext: startNextDate,
      endNext: endNextDate,
      nonOperationalPeriods,
      nonOperationalPeriodsNext,
      holidayPeriods,
      holidayPeriodsNext,
      usableFloorArea: building.data()!.usableFloorArea,
      granularity: granularity as Granularity,
      selectedIds: ids,
      type: getType(type),
      legend: [],
      tenantRootAssetId: tenantRootAssetId,
      assetId: String(id),
    });
  }

  const legendPromise = getLegend(ids.map((id) => Number(id)));

  const dataNext: Promise<Record<string, number>[]> = (
    granularity === "h" && type === "h"
      ? getEnergyDataHoursRaw(ids, startNextDate, endNextDate)
      : getEnergyDataAggregated(
          ids,
          startNextDate,
          endNextDate,
          granularity as Granularity,
        )
  )
    .then(addTotal)
    .then(
      addOperational(
        building.data()!.closedPublicHolidays,
        granularity as Granularity,
        holidayPeriodsNext,
        building.data()!.operationalHours,
      ),
    )
    .then((data) =>
      temperatureNext.then((temperature) =>
        mergeTimeseries(
          data.map((point) => ({
            ...point,
            timestamp: point.timestamp?.valueOf(),
          })),
          temperature.map((point) => ({
            timestamp: point.timestamp.valueOf(),
            temperature: point.value,
          })),
        ),
      ),
    )
    .then(async (data) => {
      const legend = await legendPromise;
      return data.map((d) => ({
        timestamp:
          granularity === "w"
            ? dayjs(d.timestamp).add(weekDiff, "week").valueOf()
            : dayjs(d.timestamp).add(dayDiff, "day").valueOf(),
        "next-timestamp": d.timestamp.valueOf(),
        "next-total": d.total,
        ...legend.reduce(
          (prev, legend) => ({
            ...prev,
            ["next-" + legend.name]: d[legend.name],
          }),
          {},
        ),
        "next-temperature": d.temperature,
        "next-operational": d.operational,
      }));
    });

  const dataCurrent: Promise<Record<string, number>[]> = (
    granularity === "h" && type === "h"
      ? getEnergyDataHoursRaw(ids, startDate, endDate)
      : getEnergyDataAggregated(
          ids,
          startDate,
          endDate,
          granularity as Granularity,
        )
  )
    .then(addTotal)
    .then(
      addOperational(
        building.data()!.closedPublicHolidays,
        granularity as Granularity,
        holidayPeriods,
        building.data()!.operationalHours,
      ),
    )
    .then((data) =>
      temperature.then((temperature) =>
        mergeTimeseries(
          data.map((point) => ({
            ...point,
            timestamp: point.timestamp?.valueOf(),
          })),
          temperature.map((point) => ({
            timestamp: point.timestamp.valueOf(),
            temperature: point.value,
          })),
        ),
      ),
    );

  const data = Promise.all([dataCurrent, dataNext]).then((result) => {
    return mergeTimeseries(result[0], result[1]);
  });

  return defer({
    data,
    start: startDate,
    end: endDate,
    startNext: startNextDate,
    endNext: endNextDate,
    nonOperationalPeriods,
    nonOperationalPeriodsNext,
    holidayPeriods,
    holidayPeriodsNext,
    usableFloorArea: building.data()!.usableFloorArea,
    granularity: granularity as Granularity,
    selectedIds: ids,
    type: getType(type),
    legend: legendPromise,
    tenantRootAssetId: tenantRootAssetId,
    assetId: String(id),
  });
}
const sortOnSystemCode = (a: Asset, b: Asset) =>
  getSystemCodeFromExternalId(a.externalId!).localeCompare(
    getSystemCodeFromExternalId(b.externalId!),
  );

export async function initializeEnergyFlow({
  params: { id },
}: LoaderFunctionArgs) {
  const tenantRootAssetId = await getDefaultRootForEnergyHierarchy(Number(id));

  const start = dayjs().subtract(1, "month").valueOf();
  const end = dayjs().valueOf();

  return redirect(
    `../energyFlow/s/${start}/e/${end}?root=${tenantRootAssetId}`,
  );
}
export async function loaderEnergyFlow({
  request,
  params: { start, end, id },
}: LoaderFunctionArgs) {
  const organizations = await getBuildingAssets({
    client: cogniteClient,
    id: Number(id),
    labels: ["oe_common_main_meter"],
  }).then((data) =>
    data
      .sort(sortOnSystemCode)
      .map((asset) => ({ id: asset.id, name: asset.metadata!.organization })),
  );

  const tenantRootAssetId =
    new URL(request.url).searchParams.get("root") ??
    (await getDefaultRootForEnergyHierarchy(Number(id)));

  const root = (
    await cogniteClient.assets.retrieve([{ id: Number(tenantRootAssetId!) }])
  )[0];

  const last7Days = getEnergyHierarchyForBuilding(
    Number(id),
    root.metadata!.organization,
    dayjs().subtract(7, "day"),
    dayjs(),
  );
  const last365Days = getEnergyHierarchyForBuilding(
    Number(id),
    root.metadata!.organization,
    dayjs().subtract(365, "day"),
    dayjs(),
  );
  const selectedPeriod = getEnergyHierarchyForBuilding(
    Number(id),
    root.metadata!.organization,
    dayjs(Number(start)),
    dayjs(Number(end)),
  );

  return {
    organizations,
    start: new Date(Number(start)),
    end: new Date(Number(end)),
    id: String(id),
    tenantRootAssetId: Number(tenantRootAssetId),
    last7Days,
    last365Days,
    selectedPeriod,
    assetIds: new Promise((resolve) => {
      last365Days.then((data) => resolve(data.assetIds));
    }),
    type: "energyFlow",
  };
}

export async function initializeSourceEnergyFlow({
  params: { id },
}: LoaderFunctionArgs) {
  const tenantRootAssetId = await getDefaultRootForEnergyHierarchy(Number(id));

  const start = dayjs().subtract(1, "month").valueOf();
  const end = dayjs().valueOf();

  return redirect(
    `../energySourceFlow/s/${start}/e/${end}?root=${tenantRootAssetId}`,
  );
}

export async function loaderEnergySourceFlow({
  request,
  params: { start, end, id },
}: LoaderFunctionArgs) {
  const organizations = getBuildingAssets({
    client: cogniteClient,
    id: Number(id),
    labels: ["oe_common_main_meter"],
  }).then((data) =>
    data
      .sort(sortOnSystemCode)
      .map((asset) => ({ id: asset.id, name: asset.metadata!.organization })),
  );

  const tenantRootAssetId =
    new URL(request.url).searchParams.get("root") ??
    (await getDefaultRootForEnergyHierarchy(Number(id)));

  const root = (
    await cogniteClient.assets.retrieve([{ id: Number(tenantRootAssetId!) }])
  )[0];

  const last7Days = getEnergySourceHierarchyForBuilding(
    Number(id),
    root.metadata!.organization,
    dayjs().subtract(7, "day"),
    dayjs(),
  );
  const last365Days = getEnergySourceHierarchyForBuilding(
    Number(id),
    root.metadata!.organization,
    dayjs().subtract(365, "day"),
    dayjs(),
  );
  const selectedPeriod = getEnergySourceHierarchyForBuilding(
    Number(id),
    root.metadata!.organization,
    dayjs(Number(start)),
    dayjs(Number(end)),
  );

  return {
    organizations,
    start: new Date(Number(start)),
    end: new Date(Number(end)),
    id: String(id),
    tenantRootAssetId: Number(tenantRootAssetId),
    last7Days,
    last365Days,
    selectedPeriod,
    assetIds: new Promise((resolve) => {
      last365Days.then((data) => resolve(data.assetIds));
    }),
    type: "energySourceFlow",
  };
}

export const getEnergyHierarchyForBuilding = async (
  id: number,
  organization: string,
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
) => {
  const assets = await cogniteClient.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        metadata: { organization },
        labels: {
          containsAny: [
            "oe_common_main_meter",
            "oe_common_serial_meter",
            "oe_common_sub_serial_meter",
          ].map((label) => ({ externalId: label })),
        },
      },
    })
    .autoPagingToArray({ limit: -1 });

  const [mainMeterAsset] = assets.filter(
    (asset) =>
      asset.labels?.find(
        (label) => label.externalId === "oe_common_main_meter",
      ),
  );
  const serialMeterAssets = assets.filter(
    (asset) =>
      asset.labels?.find(
        (label) => label.externalId === "oe_common_serial_meter",
      ),
  );

  const subSerialMeterAssets = assets.filter(
    (asset) =>
      asset.labels?.find(
        (label) => label.externalId === "oe_common_sub_serial_meter",
      ),
  );

  const timeseries = await getTimeseriesForAssets(cogniteClient, [
    ...(assets || []).map((asset) => asset.id),
  ]);

  const timeseriesIdToAssetId = timeseries.reduce<Record<number, number>>(
    (acc, ts) => ({ ...acc, [ts.id]: ts.assetId! }),
    {},
  );

  const valueMap = (
    await Promise.all(
      await cogniteClient.datapoints.retrieve({
        items: timeseries.map((ts) => ({ id: ts.id })),
        start: start.toDate(),
        end: end.toDate(),
        granularity: `${end.diff(start, "hours")}h`,
        aggregates: ["sum"],
      }),
    )
  )
    .flat()
    .reduce<Record<number, number>>((prev, result) => {
      return {
        ...prev,
        [timeseriesIdToAssetId[result.id]]:
          result.datapoints.length === 0
            ? 0
            : (result.datapoints[0] as DatapointAggregate).sum!,
      };
    }, {});

  const nodes = [
    {
      node: mainMeterAsset.id,
      name: mainMeterAsset.description,
      value: valueMap[mainMeterAsset.id],
      unit: "kWh",
    },
    ...serialMeterAssets
      .filter((asset) => valueMap[asset.id] > 0)
      .map((asset) => ({
        node: asset.id,
        name: asset.description,
        value: valueMap[asset.id],
        unit: "kWh",
      })),
    ...subSerialMeterAssets
      .filter((asset) => valueMap[asset.id] > 0)
      .map((asset) => ({
        node: asset.id,
        name: asset.description,
        value: valueMap[asset.id],
        unit: "kWh",
      })),
  ];

  const links = [
    ...serialMeterAssets
      .filter((asset) => valueMap[asset.id] > 0)
      .map((asset) => ({
        source: 0,
        target: nodes.findIndex((node) => node.node === asset.id),
        value: valueMap[asset.id],
        unit: "kWh",
      })),
    ...subSerialMeterAssets
      .filter((asset) => valueMap[asset.id] > 0)
      .map((asset) => {
        const system = getSystemCodeFromExternalId(asset.externalId!).substring(
          0,
          1,
        );
        const parentId = serialMeterAssets.find((asset) =>
          getSystemCodeFromExternalId(asset.externalId!).startsWith(system),
        )?.id;
        return {
          source: nodes.findIndex((node) => node.node === parentId),
          target: nodes.findIndex((node) => node.node === asset.id),
          value: valueMap[asset.id],
          unit: "kWh",
        };
      }),
  ];
  return { nodes, links, assetIds: assets.map((asset) => ({ id: asset.id })) };
};
export const getEnergySourceHierarchyForBuilding = async (
  id: number,
  organization: string,
  start: dayjs.Dayjs,
  end: dayjs.Dayjs,
) => {
  const assets = await cogniteClient.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id }],
        metadata: { organization },
        labels: {
          containsAny: [
            "oe_common_main_meter",
            "oe_common_main_meter_by_energy_source",
          ].map((label) => ({ externalId: label })),
        },
      },
    })
    .autoPagingToArray({ limit: -1 });

  const [mainMeterAsset] = assets.filter(
    (asset) =>
      asset.labels?.find(
        (label) => label.externalId === "oe_common_main_meter",
      ),
  );
  const serialMeterAssets = assets.filter(
    (asset) =>
      asset.labels?.find(
        (label) => label.externalId === "oe_common_main_meter_by_energy_source",
      ),
  );

  const timeseries = await getTimeseriesForAssets(cogniteClient, [
    ...(assets || []).map((asset) => asset.id),
  ]);

  const timeseriesIdToAssetId = timeseries.reduce<Record<number, number>>(
    (acc, ts) => ({ ...acc, [ts.id]: ts.assetId! }),
    {},
  );

  const valueMap = (
    await Promise.all(
      await cogniteClient.datapoints.retrieve({
        items: timeseries.map((ts) => ({ id: ts.id })),
        start: start.toDate(),
        end: end.toDate(),
        granularity: `${end.diff(start, "hours")}h`,
        aggregates: ["sum"],
      }),
    )
  )
    .flat()
    .reduce<Record<number, number>>((prev, result) => {
      return {
        ...prev,
        [timeseriesIdToAssetId[result.id]]:
          result.datapoints.length === 0
            ? 0
            : (result.datapoints[0] as DatapointAggregate).sum!,
      };
    }, {});

  const nodes = [
    {
      node: mainMeterAsset.id,
      name: mainMeterAsset.description,
      value: valueMap[mainMeterAsset.id],
      unit: "kWh",
    },
    ...serialMeterAssets
      .filter((asset) => valueMap[asset.id] > 0)
      .map((asset) => ({
        node: asset.id,
        name: asset.description,
        value: valueMap[asset.id],
        unit: "kWh",
      })),
  ];

  const links = [
    ...serialMeterAssets
      .filter((asset) => valueMap[asset.id] > 0)
      .map((asset) => ({
        source: 0,
        target: nodes.findIndex((node) => node.node === asset.id),
        value: valueMap[asset.id],
        unit: "kWh",
      })),
  ];
  return { nodes, links, assetIds: assets.map((asset) => ({ id: asset.id })) };
};
export async function loaderAnalysis({
  params: { snapshotId },
}: LoaderFunctionArgs) {
  return loadDocument(collectionAnalysis, snapshotId);
}

export async function loaderAnalyses({
  params: { id: buildingId },
  request,
}: LoaderFunctionArgs) {
  const isScatterplotAnalysisRoute = request.url.includes("scatterplot");
  const isSimpleAnalysisRoute = request.url.includes("simple");
  const { docs: analysisDocs } = await getDocs(
    query(
      collectionAnalysis,
      where("buildingId", "==", Number(buildingId)),
      where("owner", "==", keycloak.idTokenParsed?.email),
      orderBy("created", "desc"),
    ),
  );
  return analysisDocs
    .filter((analysisDoc) => {
      const analysis = analysisDoc.data();
      if (isScatterplotAnalysisRoute) {
        return isScatterplotAnalysis(analysis);
      }
      if (isSimpleAnalysisRoute) {
        return !isScatterplotAnalysis(analysis);
      }
      return true;
    })
    .map((analysisDoc) => ({
      snapshotId: analysisDoc.id,
      ...analysisDoc.data(),
    }));
}

const getAllFacetValuesForBuilding = async (
  buildingId: number,
  facetName: string,
) => {
  try {
    const filter = `buildingId = ${buildingId}`;
    const { facetHits } = await componentsIndex.searchForFacetValues({
      facetName,
      filter,
    });
    return facetHits.map((hit) => hit.value);
  } catch (error) {
    if (isErrorWithMessage(error)) {
      notification.error({
        message: error.message,
      });
    }
    console.error(
      `Failed to get facet values for building: ${buildingId}, facetName: ${facetName}, error: ${error}`,
    );
  }
  return [];
};

export async function loaderComponent({
  params: { componentId, id },
}: LoaderFunctionArgs) {
  const suppliers = getAllFacetValuesForBuilding(Number(id), "supplier");
  const contractors = getAllFacetValuesForBuilding(Number(id), "contractor");
  const manufacturers = getAllFacetValuesForBuilding(
    Number(id),
    "manufacturer",
  );
  const subBuildings = getAllFacetValuesForBuilding(
    Number(id),
    "subBuilding",
  ).then((data) =>
    data.filter((subBuilding) => {
      return subBuilding !== "Common";
    }),
  );

  const roomTree = cogniteClient.assets
    .list({
      filter: {
        assetSubtreeIds: [{ id: Number(id) }],
        labels: {
          containsAny: [
            { externalId: "sub_building" },
            { externalId: "floor" },
            { externalId: "sub_floor" },
            { externalId: "room" },
          ],
        },
      },
      limit: 1000,
    })
    .autoPagingToArray({ limit: Infinity })
    .then((assets) => {
      // return assets in a tree structure
      return assets
        .filter(
          (asset) =>
            asset.labels?.find((label) => label.externalId === "sub_building"),
        )
        .map((asset) => ({
          value: asset.externalId,
          label: formatSubBuildingFromExternalId(asset.externalId!),
          children: assets
            .filter((child) => child.parentId === asset.id)
            .map((child) => ({
              value: child.externalId,
              label: `${
                /200.0+(\d+)/.exec(child.name)?.[1] ?? child.name
              }. Etasje`,
              children: assets
                .filter((grandChild) => grandChild.parentId === child.id)
                .map((grandChild) => ({
                  value: grandChild.externalId,
                  label: `${grandChild.metadata?.room_id ?? grandChild.name} ${
                    grandChild.metadata?.specie ?? grandChild.description ?? ""
                  }`,
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    })
    .then((tree) => tree.filter((node) => node.children.length > 0));

  if (componentId === "new") {
    return {
      suppliers,
      contractors,
      manufacturers,
      subBuildings,
      component: {},
      roomTree,
      tasks: [],
      files: [],
    };
  }

  const tasks: Promise<Task[]> = getDocs(
    query(
      collection(browserFirestore, "tasks"),
      where("components", "array-contains", Number(componentId)),
    ),
  ).then((result) =>
    result.docs.map((doc) => {
      const taskData = doc.data();
      taskData.snapshotId = doc.id;
      return taskData as Task;
    }),
  );

  const componentFromCDF = (
    await cogniteClient.assets.retrieve([{ id: Number(componentId) }])
  )[0];

  const tfmComponent = getComponentFromExternalId(componentFromCDF.externalId!);
  const componentType = tfmComponent ? tfmComponent.substring(0, 2) : undefined;
  const component = {
    description: componentFromCDF.description,
    system: getSystemCodeFromExternalId(componentFromCDF.externalId!),
    subBuilding: formatSubBuildingFromExternalId(componentFromCDF.externalId!),
    componentTypeTranslated: componentType
      ? translateComponentType(componentType)
      : "",
    component: tfmComponent,
    id: componentFromCDF.id,
    externalId: componentFromCDF.externalId,
    dataSetId: componentFromCDF.dataSetId,
    buildingId: componentFromCDF.rootId,
    altComponentTag:
      componentFromCDF.metadata?.altComponentTag ?? componentFromCDF.externalId,
    placement:
      componentFromCDF.metadata?.placement ??
      (componentFromCDF.metadata?.room_no
        ? `Rom ${componentFromCDF.metadata?.room_no}`
        : undefined),
    componentType,
    deliveryDate: componentFromCDF.metadata?.delivery_date
      ? Number(componentFromCDF.metadata.delivery_date)
      : null,
    claimDate: componentFromCDF.metadata?.claim_date
      ? Number(componentFromCDF.metadata.claim_date)
      : null,
    endOfLifeDate: componentFromCDF.metadata?.end_of_life_date
      ? Number(componentFromCDF.metadata.end_of_life_date)
      : null,
    contractor: componentFromCDF.metadata?.contractor,
    supplier: componentFromCDF.metadata?.supplier,
    manufacturer: componentFromCDF.metadata?.manufacturer,
    source: componentFromCDF.metadata?.source,
    labels: componentFromCDF.labels?.map((label) => label.externalId),
    componentInformation: componentFromCDF.metadata?.component_information,
    productCode: componentFromCDF.metadata?.product_code,
  };

  const room = component.externalId
    ? await cogniteClient.relationships
        .list({
          filter: {
            targetExternalIds: [component.externalId],
            labels: {
              containsAny: [{ externalId: "U_rel_room_asset" }],
            },
          },
        })
        .autoPagingToArray({ limit: Infinity })
        .then((relationships) => {
          if (relationships.length === 0) {
            return null;
          }
          if (relationships.length > 1) {
            console.error(
              `More than one room found for component ${component.externalId}`,
            );
          }
          return {
            roomExternalId: relationships[0].sourceExternalId,
            relationshipExternalId: relationships[0].externalId,
          };
        })
    : undefined;

  const files = await cogniteClient.files
    .list({
      filter: {
        assetIds: [component.id],
      },
    })
    .autoPagingToArray({ limit: Infinity });

  return {
    suppliers,
    contractors,
    manufacturers,
    subBuildings,
    component: {
      ...component,
      deliveryDate: component.deliveryDate
        ? dayjs(Number(component.deliveryDate))
        : undefined,
      claimDate: component.claimDate
        ? dayjs(Number(component.claimDate))
        : undefined,
      endOfLifeDate: component.endOfLifeDate
        ? dayjs(Number(component.endOfLifeDate))
        : undefined,
    },
    roomTree,
    room,
    tasks,
    files: await Promise.all(
      files.map(async (file) => {
        return {
          uid: file.id.toString(),
          externalId: file.externalId,
          name: file.name,
          status: file.uploaded ? "done" : "error",
          thumbUrl: file.uploaded
            ? file?.metadata?.thumbnailUrl || (await getIcon(file.id))
            : undefined,
          url: file.uploaded
            ? file?.metadata?.url || (await getFileUrl(file.id))
            : undefined,
          mimeType: file.mimeType,
        } as UploadFile<{ mimeType: string }>;
      }),
    ),
  };
}

export async function loaderComponentPreviewFile({
  params: { componentId, fileId },
}: LoaderFunctionArgs) {
  const componentFromCDF = (
    await cogniteClient.assets.retrieve([{ id: Number(componentId) }])
  )[0];

  const files = await cogniteClient.files
    .list({
      filter: {
        assetIds: [Number(componentId)],
      },
    })
    .autoPagingToArray({ limit: Infinity });

  return {
    files,
    component: {
      id: componentFromCDF.id,
      description: componentFromCDF.description,
      component: getComponentFromExternalId(componentFromCDF.externalId!),
      system: getSystemCodeFromExternalId(componentFromCDF.externalId!),
    },
    fileId: Number(fileId),
  };
}

export async function loaderReports({
  params: { id: buildingId },
}: LoaderFunctionArgs) {
  const wasteTimeseriesList = await getTimeseriesWithLabels(
    cogniteClient,
    Number(buildingId),
    ["waste", "wastehub"],
  );

  const availableSensors = await getIndoorClimateAvailableSensors(
    cogniteClient,
    Number(buildingId),
  );

  const availableSensorsList = Object.entries(availableSensors)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map(([subBuilding, sensors]) => ({
      id: subBuilding,
      parameters: sensors,
    }));

  return {
    wasteTimeseriesList,
    subBuildingsAndSensorsParamsForIndoorClimateReport: availableSensorsList,
  };
}

export async function loaderWaste({
  params: { id: buildingId, startDate, endDate },
}: LoaderFunctionArgs) {
  const wasteTimeseriesList = await getTimeseriesWithLabels(
    cogniteClient,
    Number(buildingId),
    ["waste", "wastehub"],
  );

  const { start, end } = getStartAndEndOfTheMonthDates(startDate, endDate);
  if (wasteTimeseriesList.length === 0) {
    return { data: [], startDate: start, endDate: end, buildingId };
  }

  const datapointsRetrieve: Datapoints[] | DatapointAggregates[] =
    await cogniteClient.datapoints.retrieve({
      items: wasteTimeseriesList.map((t) => ({ id: t.id })),
      start: start.toDate(),
      end: end.toDate(),
      granularity: `${end.diff(start, "hour") + 1}h`,
      aggregates: ["sum"],
    });
  const filteredDatapoints = (
    datapointsRetrieve as DatapointAggregates[]
  ).filter((point) =>
    !point || point.datapoints.length === 0
      ? null
      : point.datapoints[0].sum ?? null,
  );

  const totalWaste = (
    datapointsRetrieve as DatapointAggregates[]
  ).reduce<number>((accumulator, currentValue) => {
    const { datapoints } = currentValue;
    if (datapoints.length === 0) {
      return accumulator;
    }
    return accumulator + (datapoints[0].sum ?? 0);
  }, 0);

  const totalUSortedWaste = wasteTimeseriesList
    .filter((ts) => ts.metadata?.waste_code?.startsWith("9"))
    .reduce<number>((acc, curr) => {
      const datapoint = (datapointsRetrieve as DatapointAggregates[]).find(
        (dp) => dp.id === curr.id,
      );
      return (
        acc +
        (!datapoint || datapoint.datapoints.length === 0
          ? 0
          : datapoint.datapoints[0].sum ?? 0)
      );
    }, 0);

  const totalSortedWaste = totalWaste - totalUSortedWaste;
  const sortedDegree =
    Math.round((totalSortedWaste / totalWaste) * 100) || undefined;

  return {
    data: wasteTimeseriesList
      .map((ts) => {
        const getFirstDatapointValue = (dp: DatapointAggregates | undefined) =>
          !dp || dp.datapoints.length === 0
            ? null
            : dp.datapoints[0].sum ?? null;
        return {
          name: ts.name!,
          description: ts.description,
          externalId: ts.externalId!,
          unit: ts.unit || "",
          value: getFirstDatapointValue(
            (filteredDatapoints as DatapointAggregates[]).find(
              (dp) => dp.id === ts.id,
            ),
          ),
        };
      })
      .filter(({ value }) => typeof value === "number"),
    startDate: start,
    endDate: end,
    totalWaste,
    sortedDegree,
    assetIds: wasteTimeseriesList
      .filter((ts) => ts.assetId !== undefined)
      .map((ts) => ts.assetId),
  };
}

export async function loaderGauge({
  params: { snapshotId },
}: LoaderFunctionArgs) {
  return loadDocument(collectionGauge, snapshotId);
}

export async function loaderGauges({
  params: { id: buildingId },
}: LoaderFunctionArgs) {
  const { docs: gaugeDocs } = await getDocs(
    query(
      collectionGauge,
      where("buildingId", "==", Number(buildingId)),
      where("owner", "==", keycloak.idTokenParsed?.email),
      orderBy("created", "desc"),
    ),
  );
  return gaugeDocs.map((gaugeDoc) => ({
    snapshotId: gaugeDoc.id,
    ...gaugeDoc.data(),
  }));
}

export async function loaderHeatMap({
  params: { snapshotId },
}: LoaderFunctionArgs) {
  return loadDocument(collectionHeatMap, snapshotId);
}

export async function loaderHeatMaps({
  params: { id: buildingId },
}: LoaderFunctionArgs) {
  const { docs: heatMapDocs } = await getDocs(
    query(
      collectionHeatMap,
      where("buildingId", "==", Number(buildingId)),
      where("owner", "==", keycloak.idTokenParsed?.email),
      orderBy("created", "desc"),
    ),
  );
  return heatMapDocs.map((heatMapDoc) => ({
    snapshotId: heatMapDoc.id,
    ...heatMapDoc.data(),
  }));
}

export async function loaderDashboard(loaderFunctionArgs: LoaderFunctionArgs) {
  const gauges = await loaderGauges(loaderFunctionArgs);
  const analyses = await loaderAnalyses(loaderFunctionArgs);
  const heatMaps = await loaderHeatMaps(loaderFunctionArgs);
  return {
    gauges,
    analyses,
    heatMaps,
  };
}

export async function loaderAdmin() {
  if (!isAdmin()) {
    throw json(null, 403);
  }
  return null;
}
/*
type

Document: Document files from Microsoft Word or similar word processing software.
PDF: PDF files.
Spreadsheet: Files from Microsoft Excel or similar spreadsheet software.
Presentation: Slides from Microsoft Powerpoint or similar.
Image: Any kind of image such as PNG or JPG files.
Video: Any kind of video such as MOV or MP4 files.
Tabular data: Csv, tsv and other kinds of tabular data files.
Plain text: Plain text files.
Compressed: ZIP files and other kinds of compressed archive files.
Script: Program code such as python or matlab.
Other: Anything that doesn't fit in any of the above types.
 */
const getTypeTranslationKey = (type = "other") => {
  return `${type.toLowerCase().replace(" ", "-")}`;
};
const getReverseTypeTranslationKey = (type: string) => {
  if (type === "pdf") {
    return "PDF";
  }
  return type
    .split("-")
    .map((t) => t[0].toUpperCase() + t.slice(1))
    .join(" ");
};
const getCogniteSortKeyForProperty = (property: string) => {
  switch (property) {
    case "name":
      return ["sourceFile", "name"];
    case "size":
      return ["sourceFile", "size"];
    case "modifiedTime":
      return ["modifiedTime"];
    default:
      return [property];
  }
};
const getFiles = async ({
  assetId,
  labels = [],
  fileTypes = [],
  search,
  sort,
  hidden = false,
  highlight = false,
  resource,
}: {
  assetId: number;
  system?: string;
  labels: string[];
  fileTypes: string[];
  search: string | null;
  sort: Sort;
  hidden?: boolean;
  highlight?: boolean;
  resource?: string;
}) => {
  const filter: DocumentFilter = {
    and: [
      {
        containsAll: {
          property: ["sourceFile", "assetIds"],
          values: [assetId],
        },
      },
      {
        not: {
          containsAny: {
            property: ["sourceFile", "labels"],
            values: [
              { externalId: "3d_model" },
              { externalId: "properate_report" },
              { externalId: "internal_schema_background" },
            ],
          },
        },
      },
    ],
  };

  if (hidden) {
    filter.and.push({
      equals: {
        property: ["sourceFile", "metadata", "visibility"],
        value: "hidden",
      },
    });
  } else {
    filter.and.push({
      not: {
        equals: {
          property: ["sourceFile", "metadata", "visibility"],
          value: "hidden",
        },
      },
    });
  }
  if (labels.length > 0) {
    filter!.and.push({
      containsAny: {
        property: ["labels"],
        values: labels.map((l) => ({ externalId: l })),
      },
    });
  }

  if (fileTypes.length > 0) {
    filter!.and.push({
      in: {
        property: ["type"],
        values: fileTypes.map((type: string) =>
          getReverseTypeTranslationKey(type),
        ),
      },
    });
  }

  if (resource?.length) {
    filter.and.push({
      prefix: {
        property: ["sourceFile", "metadata", "systems"],
        value: resource,
      },
    });
  }

  let cursor = undefined;

  let items: DocumentSearchItem[] = [];

  do {
    const response = await cogniteClient.documents.search({
      ...(search &&
        search?.length && {
          search: { query: search },
          highlight: highlight,
        }),
      filter,
      cursor,
      sort: [
        {
          property: getCogniteSortKeyForProperty(sort.property),
          order: sort.order,
        },
      ],
    });
    items = items.concat(response.items);
    cursor = response.nextCursor;
  } while (cursor);

  return items.map(
    (item) =>
      ({
        id: item.item.id,
        name: item.item.sourceFile.name,
        mimeType: item.item.sourceFile.mimeType,
        metadata: item.item.sourceFile.metadata,
        labels: item.item.sourceFile.labels,
        modifiedTime: item.item.modifiedTime
          ? new Date(item.item.modifiedTime)
          : undefined,
        size: item.item.sourceFile.size,
        highlight: item.highlight,
        type: getTypeTranslationKey(item.item.type),
      }) as ProperateFile,
  );
};

const DEFAULT_FILE_SORT: Sort = {
  property: "modifiedTime",
  order: "desc",
};

const filesQuery = (
  assetId: number,
  highlight = false,
  search: string | null,
  labels: string[],
  fileTypes: string[],
  sort: Sort,
  hidden = false,
  resource?: string,
) => ({
  queryKey: [
    "files",
    assetId,
    highlight,
    search,
    labels,
    fileTypes,
    sort,
    hidden,
    resource,
  ],
  queryFn: async () =>
    getFiles({
      assetId,
      highlight,
      search,
      labels,
      fileTypes,
      sort,
      hidden,
      resource,
    }),
});

export const loaderFiles = (queryClient: QueryClient, hidden = false) =>
  async function ({
    params: { id, search },
    request,
  }: LoaderFunctionArgs): Promise<DeferredData> {
    const assetId = Number(id);
    const building = (
      await cogniteClient.assets.retrieve([{ id: assetId }])
    )[0];
    const urlParams = new URL(request.url).searchParams;

    const labels = urlParams.getAll("label");
    const fileTypes = urlParams.getAll("fileType");

    const resource = urlParams.get("resource") || "";

    const property = urlParams.get("sort");
    const order = urlParams.get("order");

    const sort: Sort =
      property && order
        ? {
            property,
            order: order as "asc" | "desc",
          }
        : DEFAULT_FILE_SORT;

    const files = queryClient.fetchQuery({
      ...filesQuery(
        assetId,
        true,
        search || null,
        labels,
        fileTypes,
        sort,
        hidden,
        resource,
      ),
      staleTime: 1000 * 60 * 2,
    });

    return defer({
      files,
      id: assetId,
      search: search || "",
      labels,
      fileTypes,
      dataSetId: building.dataSetId,
      sort,
      hidden,
      resource,
    });
  };

class UploadTarget extends EventTarget {}

export const actionFiles =
  (queryClient: QueryClient, hidden = false) =>
  async ({ params: { id, search }, request }: ActionFunctionArgs) => {
    const assetId = Number(id);
    const building = (
      await cogniteClient.assets.retrieve([{ id: assetId }])
    )[0];
    const urlParams = new URL(request.url).searchParams;

    const labels = urlParams.getAll("label");
    const fileTypes = urlParams.getAll("fileType");

    const resource = urlParams.get("resource") || "";

    const property = urlParams.get("sort");
    const order = urlParams.get("order");

    const sort: Sort =
      property && order
        ? {
            property,
            order: order as "asc" | "desc",
          }
        : DEFAULT_FILE_SORT;
    const filesQueryKey = filesQuery(
      assetId,
      true,
      search || null,
      labels,
      fileTypes,
      sort,
      hidden,
      resource,
    ).queryKey;

    if (request.method === "PUT") {
      const data = await request.formData();
      const uploadFiles = data.getAll("file") as File[];

      return Promise.all(
        uploadFiles.map(async (uploadFile) => {
          const upload = new UploadTarget();
          const file: FileUploadResponse = (await cogniteClient.files.upload({
            name: uploadFile.name,
            dataSetId: building.dataSetId,
            assetIds: [assetId],
            metadata: {
              fileSize: uploadFile.size.toString(),
            },
          })) as FileUploadResponse;
          const newFile: ProperateFile = {
            id: file.id,
            name: file.name,
            mimeType: file.mimeType || "application/octet-stream",
            metadata: file.metadata ?? {},
            labels: file.labels,
            modifiedTime: file.createdTime,
            type: "other",
            size: uploadFile.size,
          };

          queryClient.setQueryData<ProperateFile[]>(filesQueryKey, (oldData) =>
            oldData ? [newFile, ...oldData] : [newFile],
          );

          axios
            .put((file as FileUploadResponse).uploadUrl, uploadFile, {
              onUploadProgress: (progressEvent) => {
                upload.dispatchEvent(
                  new CustomEvent("progress", {
                    detail: {
                      name: uploadFile.name,
                      loaded: progressEvent.loaded,
                      total: progressEvent.total,
                    },
                  }),
                );
              },
            })
            .then(() => {
              if (
                fileExtensionsForAutodeskViewer.includes(
                  uploadFile.name.split(".").pop()!.toUpperCase(),
                )
              ) {
                triggerGetUrn(file.id.toString()).then((res) => {
                  queryClient.setQueryData<ProperateFile[]>(
                    filesQueryKey,
                    (oldData) =>
                      oldData
                        ? oldData.reduce((acc, file) => {
                            if (file.id === Number(file.id)) {
                              const updatedFile: ProperateFile = {
                                ...file,
                                metadata: {
                                  ...file.metadata,
                                  urn: res.data,
                                },
                              };
                              return [...acc, updatedFile];
                            }

                            return [...acc, file];
                          }, [] as ProperateFile[])
                        : oldData,
                  );
                });
              }
            });
          return upload;
        }),
      );
    }
    const data = await request.json();
    if (request.method === "DELETE") {
      const deleteIds = data.ids as number[];
      await cogniteClient.files.delete(deleteIds.map((id) => ({ id })));
      queryClient.setQueryData<ProperateFile[]>(filesQueryKey, (oldData) =>
        oldData
          ? oldData.filter((file) => !deleteIds.includes(file.id))
          : oldData,
      );
    }
    if (request.method === "PATCH") {
      const deleteIds = data.ids as number[];
      await cogniteClient.files.update(
        deleteIds.map((id) => ({
          id: id,
          update: {
            metadata: { set: { visibility: hidden ? "visible" : "hidden" } },
          },
        })),
      );
      queryClient.setQueryData<ProperateFile[]>(filesQueryKey, (oldData) =>
        oldData
          ? oldData.filter((file) => !deleteIds.includes(file.id))
          : oldData,
      );
      // now the trash or files endpoint is no longer valid so update it
      const filesQueryKeyAlternate = filesQuery(
        assetId,
        true,
        search || null,
        labels,
        fileTypes,
        sort,
        !hidden,
        resource,
      ).queryKey;
      queryClient.invalidateQueries({ queryKey: filesQueryKeyAlternate });
    }
    if (request.method === "POST") {
      const changes = data.changes as UpdateFile[];
      await cogniteClient.files.update(changes);

      queryClient.setQueryData<ProperateFile[]>(filesQueryKey, (oldData) =>
        oldData
          ? oldData.reduce<ProperateFile[]>((acc, file) => {
              const change = changes.find((c) => c.id === file.id);
              if (change) {
                const updatedLabels = change.update.labels
                  ? {
                      labels: [
                        ...(file.labels
                          ? file.labels.filter(
                              (label) =>
                                ![
                                  ...change.update.labels!.remove,
                                  ...change.update.labels!.add,
                                ].some(
                                  (l) => label.externalId === l.externalId,
                                ),
                            )
                          : []),
                        ...change.update.labels.add,
                      ],
                    }
                  : {};

                const updatedSystems =
                  change.update.metadata &&
                  change.update.metadata.set.systems !== "undefined"
                    ? {
                        metadata: {
                          ...file.metadata,
                          systems: change.update.metadata.set.systems,
                        },
                      }
                    : {};
                const updatedFile: ProperateFile = {
                  ...file,
                  ...updatedLabels,
                  ...updatedSystems,
                };
                return [...acc, updatedFile];
              }
              return [...acc, file];
            }, [])
          : undefined,
      );
    }
    return redirect(request.url);
  };

export const loaderFilesPreview = (queryClient: QueryClient, hidden = false) =>
  async function ({
    params: { id, search, fileId },
    request,
  }: LoaderFunctionArgs): Promise<DeferredData> {
    const assetId = Number(id);
    const urlParams = new URL(request.url).searchParams;

    const labels = urlParams.getAll("label");
    const fileTypes = urlParams.getAll("fileType");

    const resource = urlParams.get("resource") || "";

    const property = urlParams.get("sort");
    const order = urlParams.get("order");

    const sort: Sort =
      property && order
        ? {
            property,
            order: order as "asc" | "desc",
          }
        : DEFAULT_FILE_SORT;

    const files = queryClient.fetchQuery({
      ...filesQuery(
        assetId,
        true,
        search || null,
        labels,
        fileTypes,
        sort,
        hidden,
        resource,
      ),
      staleTime: 1000 * 60 * 2,
    });
    return defer({
      files,
      fileId: Number(fileId),
      hidden,
      search,
    });
  };

export const actionFilesPreview =
  (queryClient: QueryClient, hidden = false) =>
  async ({ params: { id, search, fileId }, request }: ActionFunctionArgs) => {
    const assetId = Number(id);
    const urlParams = new URL(request.url).searchParams;

    const labels = urlParams.getAll("label");
    const fileTypes = urlParams.getAll("fileType");

    const resource = urlParams.get("resource") || "";

    const property = urlParams.get("sort");
    const order = urlParams.get("order");

    const sort: Sort =
      property && order
        ? {
            property,
            order: order as "asc" | "desc",
          }
        : DEFAULT_FILE_SORT;
    const filesQueryKey = filesQuery(
      assetId,
      true,
      search || null,
      labels,
      fileTypes,
      sort,
      hidden,
      resource,
    ).queryKey;

    const files = await queryClient.fetchQuery({
      ...filesQuery(
        assetId,
        true,
        search || null,
        labels,
        fileTypes,
        sort,
        hidden,
        resource,
      ),
      staleTime: 1000 * 60 * 2,
    });

    if (request.method === "PATCH") {
      triggerGetUrn(Number(fileId).toString()).then((res) => {
        queryClient.setQueryData<ProperateFile[]>(filesQueryKey, (oldData) =>
          oldData
            ? oldData.reduce((acc, file) => {
                if (file.id === Number(fileId)) {
                  const updatedFile: ProperateFile = {
                    ...file,
                    metadata: {
                      ...file.metadata,
                      urn: res.data,
                    },
                  };
                  return [...acc, updatedFile];
                }

                return [...acc, file];
              }, [] as ProperateFile[])
            : oldData,
        );
      });
    }

    if (request.method === "DELETE") {
      const data = await request.json();
      await cogniteClient.files.update([
        {
          id: data.fileId,
          update: {
            metadata: { set: { visibility: hidden ? "visible" : "hidden" } },
          },
        },
      ]);
      queryClient.setQueryData<ProperateFile[]>(filesQueryKey, (oldData) =>
        oldData
          ? oldData.filter((file) => Number(fileId) !== file.id)
          : oldData,
      );
      // now the trash or files endpoint is no longer valid so update it
      const filesQueryKeyAlternate = filesQuery(
        assetId,
        true,
        search || null,
        labels,
        fileTypes,
        sort,
        !hidden,
        resource,
      ).queryKey;
      queryClient.invalidateQueries({ queryKey: filesQueryKeyAlternate });

      if (files.length === 1) {
        return redirect("../files");
      }

      const index = files.findIndex((file) => file.id === Number(fileId));

      const newFileId = index === 0 ? files[index + 1].id : files[index - 1].id;

      return redirect(
        getUrl(
          assetId,
          search || "",
          labels,
          fileTypes,
          sort,
          hidden,
          resource,
          newFileId,
        ),
      );
    }
    return redirect(request.url);
  };
