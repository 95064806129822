import { useParams } from "react-router-dom";
import useSWR from "swr";
import { useMemo } from "react";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { Note, useNotes } from "@/features/notes";
import { useAlarmDetailsTimeSpan } from "@/pages/alarms/details/hooks/useAlarmDetailsTimeSpan";
import type { AlarmDeviation } from "@properate/common";

export type AlarmDeviationWithNotes = AlarmDeviation & {
  notes: Note[];
};

type NoteDeviationComparison = {
  note: Note;
  deviation: AlarmDeviation;
};

function noteAndDeviationSharesAsset({
  note,
  deviation,
}: NoteDeviationComparison): boolean {
  return note.assetIds.some((noteAssetId) =>
    (deviation.assetIds ?? []).includes(noteAssetId),
  );
}

function noteAndDeviationOverlapsTime({
  note,
  deviation,
}: NoteDeviationComparison): boolean {
  const deviationStartTime =
    typeof deviation.startTime === "number"
      ? deviation.startTime
      : deviation.startTime?.getTime();

  const deviationEndTime =
    typeof deviation.endTime === "number"
      ? deviation.endTime
      : deviation.endTime?.getTime();

  if (!deviationStartTime && !deviationEndTime) {
    return false;
  }

  if (deviationStartTime && !deviationEndTime) {
    return note.endTime > deviationStartTime;
  }

  if (deviationEndTime && !deviationStartTime) {
    return note.startTime < deviationEndTime;
  }

  return !(
    deviationStartTime! > note.endTime || note.startTime > deviationEndTime!
  );
}

function getDeviationsWithNotes({
  notes,
  deviations,
}: {
  notes: Note[];
  deviations: AlarmDeviation[];
}): AlarmDeviationWithNotes[] {
  const deviationsWithNotes: AlarmDeviationWithNotes[] = [];

  for (const deviation of deviations) {
    const notesForDeviation = [];
    for (const note of notes) {
      if (
        noteAndDeviationSharesAsset({ note, deviation }) &&
        noteAndDeviationOverlapsTime({ note, deviation })
      ) {
        notesForDeviation.push(note);
      }
    }
    deviationsWithNotes.push({
      ...deviation,
      notes: notesForDeviation,
    });
  }
  return deviationsWithNotes;
}

export function useAlarmDeviations() {
  const { alarmId } = useParams();
  const { client } = useCogniteClient();
  const timeSpan = useAlarmDetailsTimeSpan();
  const { data, isLoading, error } = useSWR(
    ["AlarmEventList", alarmId, timeSpan],
    () => {
      return alarmId
        ? client.events
            .list({
              filter: {
                metadata: { alarm_definition_id: alarmId },
                activeAtTime: { min: timeSpan[0], max: timeSpan[1] },
              },
            })
            .autoPagingToArray({ limit: Infinity })
        : [];
    },
  );

  return {
    deviations: data as AlarmDeviation[],
    isLoading,
    error,
  };
}

export function useAlarmDeviationsWithNotes() {
  const { notes } = useNotes();
  const { deviations, isLoading, error } = useAlarmDeviations();

  const deviationsWithNotes = useMemo(() => {
    if (!notes || !deviations) {
      return [];
    }

    return getDeviationsWithNotes({
      notes: notes ?? [],
      deviations,
    });
  }, [notes, deviations]);

  return { deviations: deviationsWithNotes, isLoading, error };
}
