import { Checkbox, Col, Modal, Row } from "antd";
import { useTranslations, MessageKey } from "@properate/translations";
import {
  alarmKPIConfiguration,
  alarmKPIs,
  buildingActivityKPIConfiguration,
  buildingActivityKPIs,
  certificationKPIConfiguration,
  certificationKPIs,
  energyKPIs,
  indoorClimateKPIs,
  tasksKPIConfiguration,
  tasksKPIs,
  waterKPIs,
  weatherKPIConfiguration,
  weatherKPIs,
} from "@/utils/kpi";
import { KpiGroupTitle } from "./elements";
import { KPICheckboxGroupWithRows } from "./KPICheckboxGroupWithRows";
import {
  energyKPIOptions,
  indoorClimateOptions,
  waterKPIOptions,
} from "./options";
import type {
  AlarmKPI,
  BuildingActivityKPI,
  CertificationKPI,
  EnergyKPI,
  IndoorClimateKPI,
  KPI,
  TasksKPIs,
  WaterKPI,
  WeatherKPI,
} from "@/utils/types";

interface Props {
  open: boolean;
  title: string;
  onHide: () => void;
  selectedValues: readonly KPI[];
  onUpdateSelectedValues: (values: KPI[]) => unknown;
  disabledKPIGroups?: Array<"certifications" | "alarms">;
}

export function KPIModal({
  open,
  title,
  onHide,
  selectedValues,
  disabledKPIGroups = [],
  onUpdateSelectedValues,
}: Props) {
  const t = useTranslations();
  const selectedEnergyKPIs = selectedValues.filter((value) =>
    energyKPIs.some((energyKPI) => energyKPI === value),
  );
  const selectedIndoorClimateKPIs = selectedValues.filter((value) =>
    indoorClimateKPIs.some((indoorClimateKPI) => indoorClimateKPI === value),
  );
  const selectedWaterKPIs = selectedValues.filter((value) =>
    waterKPIs.some((waterKPI) => waterKPI === value),
  );
  const selectedBuildingActivityKPIs = selectedValues.filter((value) =>
    buildingActivityKPIs.some(
      (buildingActivityKPI) => buildingActivityKPI === value,
    ),
  );
  const selectedWeatherKPIs = selectedValues.filter((value) =>
    weatherKPIs.some((weatherKPI) => weatherKPI === value),
  );
  const selectedAlarmKPIs = selectedValues.filter((value) =>
    alarmKPIs.some((alarmKPI) => alarmKPI === value),
  );
  const selectedCertificationKPIs = selectedValues.filter((value) =>
    certificationKPIs.some((certificationKPI) => certificationKPI === value),
  );
  const selectedTasksKPIs = selectedValues.filter((value) =>
    tasksKPIs.some((tasksKPI) => tasksKPI === value),
  );

  function handleSelectKPI(
    availableKPIsForType: readonly KPI[],
    selectedKPIsForType: readonly KPI[],
  ) {
    const selectedValuesWithoutAvailableKPIsForType = selectedValues.filter(
      (value) => !availableKPIsForType.includes(value),
    );
    onUpdateSelectedValues(
      selectedValuesWithoutAvailableKPIsForType.concat(selectedKPIsForType),
    );
  }

  return (
    <Modal
      title={title}
      open={open}
      onOk={onHide}
      onCancel={onHide}
      cancelButtonProps={{ hidden: true }}
      width={1000}
      style={{ maxWidth: "100%" }}
    >
      <h2>{t("portfolio-view.kpi.sub-title")}</h2>
      <Row>
        <Col span={12}>
          <KpiGroupTitle>{t("portfolio-view.kpi.energy")} </KpiGroupTitle>
          <KPICheckboxGroupWithRows
            value={selectedEnergyKPIs}
            onChange={(kpis) =>
              handleSelectKPI(energyKPIs, kpis as EnergyKPI[])
            }
            options={energyKPIOptions}
          />
          <KpiGroupTitle>
            {t("portfolio-view.kpi.user-occupancy-tenant")}
          </KpiGroupTitle>
          <Checkbox.Group
            options={buildingActivityKPIs.map((buildingActivityKPI) => ({
              label: t(
                `portfolio-view.kpi.labels.${buildingActivityKPIConfiguration[buildingActivityKPI].asOption.label}` as MessageKey,
              ),
              value: buildingActivityKPI,
            }))}
            value={selectedBuildingActivityKPIs}
            onChange={(values) =>
              handleSelectKPI(
                buildingActivityKPIs,
                values as BuildingActivityKPI[],
              )
            }
          />
          {!disabledKPIGroups.includes("certifications") && (
            <>
              <KpiGroupTitle>
                {t("portfolio-view.kpi.building-energy-label")}
              </KpiGroupTitle>
              <Checkbox.Group
                options={certificationKPIs.map((certificationKPI) => ({
                  label: t(
                    `portfolio-view.kpi.labels.${certificationKPIConfiguration[certificationKPI].asOption.label}` as MessageKey,
                  ),
                  value: certificationKPI,
                }))}
                value={selectedCertificationKPIs}
                onChange={(values) =>
                  handleSelectKPI(
                    certificationKPIs,
                    values as CertificationKPI[],
                  )
                }
              />
            </>
          )}
          <KpiGroupTitle>{t("portfolio-view.kpi.water")}</KpiGroupTitle>
          <KPICheckboxGroupWithRows
            value={selectedWaterKPIs}
            onChange={(values) =>
              handleSelectKPI(waterKPIs, values as WaterKPI[])
            }
            options={waterKPIOptions}
          />
        </Col>
        <Col span={12}>
          <KpiGroupTitle>
            {t("portfolio-view.kpi.indoor-climate")}
          </KpiGroupTitle>
          <KPICheckboxGroupWithRows
            value={selectedIndoorClimateKPIs}
            onChange={(kpis) =>
              handleSelectKPI(indoorClimateKPIs, kpis as IndoorClimateKPI[])
            }
            options={indoorClimateOptions}
          />
          <KpiGroupTitle>{t("portfolio-view.kpi.weather")}</KpiGroupTitle>
          <Checkbox.Group
            options={weatherKPIs.map((weatherKPI) => ({
              label: t(
                `portfolio-view.kpi.labels.${weatherKPIConfiguration[weatherKPI].asOption.label}` as MessageKey,
              ),
              value: weatherKPI,
            }))}
            value={selectedWeatherKPIs}
            onChange={(kpis) =>
              handleSelectKPI(weatherKPIs, kpis as WeatherKPI[])
            }
          />
          {!disabledKPIGroups.includes("alarms") && (
            <>
              <KpiGroupTitle>{t("portfolio-view.kpi.events")}</KpiGroupTitle>
              <Checkbox.Group
                options={alarmKPIs.map((alarmKPI) => ({
                  label: t(
                    `portfolio-view.kpi.labels.${alarmKPIConfiguration[alarmKPI].asOption.label}` as MessageKey,
                  ),
                  value: alarmKPI,
                }))}
                value={selectedAlarmKPIs}
                onChange={(values) =>
                  handleSelectKPI(alarmKPIs, values as AlarmKPI[])
                }
              />
            </>
          )}
          <KpiGroupTitle>{t("portfolio-view.kpi.tasks")}</KpiGroupTitle>
          <Checkbox.Group
            options={tasksKPIs.map((tasksKPI) => ({
              label: t(
                `portfolio-view.kpi.labels.${tasksKPIConfiguration[tasksKPI].asOption.label}` as MessageKey,
              ),
              value: tasksKPI,
            }))}
            value={selectedTasksKPIs}
            onChange={(values) =>
              handleSelectKPI(tasksKPIs, values as TasksKPIs[])
            }
          />
        </Col>
      </Row>
    </Modal>
  );
}
