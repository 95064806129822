import { CogniteClient, ExternalId } from "@cognite/sdk";
import { ReactFlowInstance } from "reactflow";

const getNodeId = (nodeType: string) => `node_${nodeType}_${+Date.now()}`;

function splitExternalId(id: ExternalId) {
  const regex = /([^+]*)\+([^=]*)=([^-]*)-(.*)/gm;
  const result = regex.exec(id.externalId);
  if (result)
    return {
      building: result[1],
      subBuilding: result[2],
      system: result[3],
      suffix: result[4],
    };
  return { building: "", subBuilding: "", system: "", suffix: "" };
}

const internalIdFromExternalId = async (
  externalId: ExternalId,
  client: CogniteClient,
) => {
  const res = await client.timeseries.retrieve([externalId]);
  return res[0].id;
};

const timeseriesFromExternalId = async (
  externalId: ExternalId,
  client: CogniteClient,
) => {
  const res = await client.timeseries.retrieve([externalId]);
  return res[0];
};

function updateReactFlowNodeData<T>(
  reactFlowInstance: ReactFlowInstance,
  nodeId: string,
  attribute: keyof T,
  value: T[keyof T],
) {
  reactFlowInstance.setNodes((nodes) =>
    nodes.map((node) => {
      if (node.id === nodeId) {
        return {
          ...node,
          data: {
            ...node.data,
            [attribute]: value,
          },
        };
      }
      return node;
    }),
  );
}

type PartialNodeData<T> = {
  [key in keyof T]: T[keyof T];
};

function updateReactFlowNodeDataPartial<T>(
  reactFlowInstance: ReactFlowInstance,
  nodeId: string,
  newNodeData: PartialNodeData<T>,
) {
  reactFlowInstance.setNodes((nodes) =>
    nodes.map((node) => {
      if (node.id === nodeId) {
        return {
          ...node,
          data: {
            ...node.data,
            ...newNodeData,
          },
        };
      }
      return node;
    }),
  );
}

function getBBFromExternalId(externalId: ExternalId) {
  const component = externalId.externalId.match(
    /-(?<component>\w\w\d\d\d)[^-]*$/,
  );
  if (component && component.groups)
    return component.groups["component"].substring(0, 2);
  return null;
}

function replaceReactFlowNodeData<T>(
  reactFlowInstance: ReactFlowInstance,
  nodeId: string,
  data: T,
) {
  reactFlowInstance.setNodes((nodes) =>
    nodes.map((node) => {
      if (node.id === nodeId) {
        return {
          ...node,
          data: {
            ...data,
          },
        };
      }
      return node;
    }),
  );
}

const findAvailableTimeseriesExternalId = async (
  client: CogniteClient,
  parentExternalId: string,
  bb: string,
) => {
  for (const counter of Array(999).keys()) {
    const trialExternalId = `${parentExternalId}-${bb}${String(
      counter + 1,
    ).padStart(3, "0")}`;
    try {
      await client.timeseries.retrieve([{ externalId: trialExternalId }]);
    } catch {
      return { externalId: trialExternalId };
    }
  }
  throw new Error("No external id available");
};

export {
  getNodeId,
  splitExternalId,
  internalIdFromExternalId,
  timeseriesFromExternalId,
  updateReactFlowNodeData,
  updateReactFlowNodeDataPartial,
  replaceReactFlowNodeData,
  findAvailableTimeseriesExternalId,
  getBBFromExternalId,
};
