import { browserFirestore } from "@properate/firebase";
import { useEffect, useState } from "react";
import { SortEnd } from "react-sortable-hoc";
import { SchemaType, SchemaTypes, SensorList } from "@properate/common";
import { arrayMoveImmutable } from "array-move";
import { collection, deleteField, doc, updateDoc } from "firebase/firestore";
import { useUser } from "@properate/auth";
import { updateSchema } from "@/services/schemas";
import { debugging } from "@/utils/debugging";
import { SelectKpisModal } from "./SelectKpisModal";
import { SortableKPIList } from "./SortableKPIList";

type Props = {
  type: SchemaTypes;
  snapshotId: string;
  schema: Pick<SchemaType, "selectedSensors" | "sensors">;
};

export const SchemaKpis = ({ type, snapshotId, schema }: Props) => {
  const user = useUser();
  const [showSettings, setShowSettings] = useState(false);
  const [selectedKpis, setSelectedKpis] = useState<any[]>([]);
  const [availableKpis, setAvailableKpis] = useState<any[]>([]);

  const deleteTimeseries = async (
    id: string,
    sensors: any,
    sensorId: string,
    timeseries: number,
    selectedSensors: any,
  ) => {
    const newSensor = {
      ...sensors[sensorId],
      timeseriesInfo: sensors[sensorId].timeseriesInfo.filter(
        (ts: any) => ts.id !== timeseries,
      ),
    };
    if (newSensor.timeseriesInfo.length === 0) {
      // first remove it from the selected ones
      if (selectedSensors.find((s: any) => s.id === sensorId)) {
        debugging.record("delete kpi schema sensors", {
          schema_id: id,
          sensor_id: sensorId,
          patch: {
            selectedSensors: selectedSensors.filter(
              (s: any) => s.id !== sensorId,
            ),
          },
          schemaType: type,
        });
        await updateDoc(doc(collection(browserFirestore, type), id), {
          selectedSensors: selectedSensors.filter(
            (s: any) => s.id !== sensorId,
          ),
        });
      }
      debugging.record("delete kpi schema sensors", {
        schema_id: id,
        sensor_id: sensorId,
        schemaType: type,
      });
      await updateDoc(doc(collection(browserFirestore, type), id), {
        [`sensor.${sensorId}`]: deleteField(),
      });
    } else {
      debugging.record("update kpi schema sensors", {
        schema_id: id,
        patch: {
          sensors: {
            ...sensors,
            [sensorId]: newSensor,
          },
        },
        sensor_id: sensorId,
        schemaType: type,
      });
      await updateDoc(doc(collection(browserFirestore, type), id), {
        sensors: {
          ...sensors,
          [sensorId]: newSensor,
        },
      });
    }
  };

  const setTimeseries = async (
    id: string,
    sensorId: string,
    sensor: SensorList,
    val: any,
  ) => {
    debugging.record("set kpi schema timeseries", {
      schema_id: id,
      sensor_id: sensorId,
      schemaType: type,
      patch: {
        ...sensor,
        timeseriesInfo: [
          ...sensor.timeseriesInfo.filter((ts: any) => ts.id !== val.id),
          val,
        ],
      },
    });
    await updateDoc(doc(collection(browserFirestore, type), id), {
      [`sensor.${sensorId}`]: {
        ...sensor,
        timeseriesInfo: [
          ...sensor.timeseriesInfo.filter((ts: any) => ts.id !== val.id),
          val,
        ],
      },
    });
  };

  async function handleSortEnd({ oldIndex, newIndex }: SortEnd) {
    await updateSchema(type, snapshotId, {
      selectedSensors: arrayMoveImmutable(
        schema.selectedSensors || [],
        oldIndex,
        newIndex,
      ),
    });
  }

  useEffect(() => {
    if (schema) {
      const other: any[] = [];

      const selected = (schema.selectedSensors || []).map((s) => {
        const ts = schema.sensors[s.id]?.timeseriesInfo.find(
          (ts) => ts.id === s.timeseries,
        );
        if (ts) {
          return { ...ts, type: s.id };
        }
        return [];
      });

      Object.entries(schema.sensors || []).forEach((entry) => {
        entry[1].timeseriesInfo.forEach((ts) => {
          if (
            ts.id &&
            (schema.selectedSensors || []).find(
              (s) => s.id === entry[0] && s.timeseries === ts.id,
            )
          ) {
            // selected.push({ ...ts, type: entry[0] });
          } else {
            other.push({ ...ts, type: entry[0] });
          }
        });
      });

      setSelectedKpis(selected);
      setAvailableKpis(other);
    }
  }, [schema]);

  return (
    <>
      <div style={{ flexDirection: "row", flexWrap: "nowrap" }}>
        <div style={{ flexGrow: 1 }}>
          <div style={{ overflow: "hidden" }}>
            <SortableKPIList
              useDragHandle
              kpis={selectedKpis}
              onChangeTimeseriesInfo={async (type, timeseries) =>
                await setTimeseries(
                  snapshotId,
                  type,
                  schema.sensors[type],
                  timeseries,
                )
              }
              onDeleteTimeseries={async (kpiId, timeseries) =>
                await deleteTimeseries(
                  snapshotId,
                  schema.sensors,
                  kpiId,
                  timeseries,
                  schema.selectedSensors || [],
                )
              }
              axis="x"
              onSortEnd={handleSortEnd}
              isSettingsButtonVisible={
                selectedKpis.length + availableKpis.length > 0
              }
              isSettingsButtonDisabled={user.isViewer}
              onClickSettingsButton={() => setShowSettings(true)}
            />
          </div>
        </div>
      </div>

      {showSettings && (
        <SelectKpisModal
          onHide={() => setShowSettings(false)}
          schema={schema}
          schemaId={snapshotId}
          schemaType={type}
        />
      )}
    </>
  );
};
