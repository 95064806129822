import { useNavigate } from "react-router-dom";
import { useTranslations } from "@properate/translations";
import { useActiveEventsByBuilding } from "@/services/alarms";
import { SEVERITY } from "@/pages/alarms/severity";
import { TableWithoutDefaultSort } from "@/components/TableWithoutDefaultSort/TableWithoutDefaultSort";
import { WidgetHeader } from "../widget-components/WidgetHeader";
import { DraggableCard } from "../draggable/DraggableCard";

interface Props {
  id: number;
  width: number;
  height: number;
  onClickRemoveButton: () => unknown;
}

export function AlarmsWidget({
  id,
  width,
  height,
  onClickRemoveButton,
}: Props) {
  const t = useTranslations();

  const navigate = useNavigate();
  const headerHeight = 46;
  const events = useActiveEventsByBuilding(id);

  return (
    <DraggableCard
      bordered={false}
      style={{ width, height: height }}
      bodyStyle={{ padding: 24 }}
      title={
        <WidgetHeader
          text={t("dashboard.widgets.alarms.title")}
          onClickRemoveButton={onClickRemoveButton}
          isDraggable
        />
      }
    >
      <div>
        {events && (
          <TableWithoutDefaultSort
            size="small"
            scroll={{
              y: height - 48 - 27 - headerHeight,
            }}
            pagination={false}
            rowKey="id"
            onRow={({ alarmId }) => ({
              onClick: () => navigate(`/asset/${id}/alarms/${alarmId}`),
            })}
            rowIsHoverable
            showHeader={false}
            locale={{
              emptyText: t("dashboard.widgets.alarms.no-deviations"),
            }}
            columns={[
              {
                title: t("dashboard.widgets.alarms.type"),
                width: 36,
                dataIndex: "severity",
                render: (_, { severity }) => (
                  <span style={{ fontSize: "32px" }}>{SEVERITY[severity]}</span>
                ),
              },
              {
                title: t("dashboard.widgets.alarms.alarm"),
                dataIndex: "name",
                render: (_, { description, name }) => (
                  <>
                    <b>
                      {name || t("dashboard.widgets.alarms.untitled")}&nbsp;
                    </b>
                    {description}
                  </>
                ),
              },
            ]}
            dataSource={events}
          />
        )}
      </div>
    </DraggableCard>
  );
}
