import { useState } from "react";
import { ExternalId } from "@cognite/sdk";
import { Button, Tooltip } from "antd";
import { LineChartOutlined } from "@ant-design/icons";
import { useCogniteClient } from "@/context/CogniteClientContext";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { GraphModal } from "../../../common/SchemaGraph/GraphModal";
import { GraphModalData } from "../../types";
import { internalIdFromExternalId } from "../helpers/Utils";

interface Props {
  externalId: ExternalId | null;
  noExternalIdTooltip: string;
  style?: React.CSSProperties;
}

function TimeSeriesGraphButton({
  externalId,
  noExternalIdTooltip: tooltip,
  style = {},
}: Props) {
  const { client } = useCogniteClient();
  const currentBuildingId = useCurrentBuildingId();
  const [showTimeseriesData, setShowTimeseriesData] =
    useState<GraphModalData | null>(null);

  return (
    <div style={style}>
      <Tooltip
        placement="topLeft"
        title={tooltip}
        trigger={externalId === null ? ["hover"] : []}
      >
        <Button
          onClick={async () => {
            if (externalId) {
              setShowTimeseriesData({
                id: await internalIdFromExternalId(externalId, client),
                unit: "",
              });
            }
          }}
          size="small"
          disabled={!externalId}
        >
          <LineChartOutlined />
        </Button>
      </Tooltip>
      {showTimeseriesData && (
        <GraphModal
          timeseriesInfo={showTimeseriesData}
          setTimeseriesInfo={setShowTimeseriesData}
          hide={() => setShowTimeseriesData(null)}
          expanded
          editable
          showDocuments={false}
          buildingId={currentBuildingId}
        />
      )}
    </div>
  );
}

export default TimeSeriesGraphButton;
