import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { WithSnapshotId } from "@properate/common";
import { useTranslations } from "@properate/translations";
import {
  mutateUserSettings,
  useUserSettingsForBuilding,
} from "@/services/userSettings";
import {
  Analysis,
  SimpleGraph,
  isScatterplotAnalysis,
  useGetImmutableTimeSpan,
  ScatterplotGraph,
  SettingsTimeseriesSimple,
} from "@/features/analysis";
import { AnalysisPresetTimeSpan } from "@/utils/types";
import { useCurrentBuildingId } from "@/hooks/useCurrentBuildingId";
import { useGetTimeseriesList } from "@/hooks/useGetTimeseriesList";
import { useTimeseriesSettings } from "@/services/timeseriesSettings";
import { SpinnerWithDelay } from "@/components/SpinnerWithDelay/SpinnerWithDelay";
import { SettingsTimeseriesScatterplot } from "../../../../../features/analysis/types";
import { DraggableCard } from "../../draggable/DraggableCard";
import { WidgetHeader } from "../../widget-components/WidgetHeader";
import { fromPresetTimeSpanToTimeSpan } from "./utils";
import { PresetTimeSpanSelect } from "./PresetTimeSpanSelect";

interface Props {
  analysis: WithSnapshotId<Analysis>;
  width: number;
  height: number;
  onClickRemoveButton: () => unknown;
}

export function AnalysisWidget({
  analysis,
  width,
  height,
  onClickRemoveButton,
}: Props) {
  const t = useTranslations();
  const currentBuildingId = useCurrentBuildingId();
  const navigate = useNavigate();
  const { data: userSettingsForBuilding } = useUserSettingsForBuilding();
  const timeSpanComplete = useGetImmutableTimeSpan(
    analysis.settingsTimeseriesList,
  );
  const { settingsTimeseriesList } = analysis;
  const { timeseriesList } = useGetTimeseriesList(
    settingsTimeseriesList.map(({ id }) => id),
  );
  const { overrideUnits, isLoading: isLoadingOverrideUnit = false } =
    useTimeseriesSettings(currentBuildingId);

  const presetTimeSpan =
    userSettingsForBuilding?.settingsAnalyses?.presetTimeSpanPerAnalysis?.[
      analysis.snapshotId
    ];
  const headerHeight = 46;
  const timeSpan =
    presetTimeSpan === "complete"
      ? timeSpanComplete
      : fromPresetTimeSpanToTimeSpan(presetTimeSpan || "last7Days");

  const handleChangePresetTimeSpan = useCallback(
    (presetTimeSpan: AnalysisPresetTimeSpan) => {
      mutateUserSettings({
        buildings: {
          [currentBuildingId]: {
            settingsAnalyses: {
              presetTimeSpanPerAnalysis: {
                [analysis.snapshotId]: presetTimeSpan || null,
              },
            },
          },
        },
      });
    },
    [analysis.snapshotId, currentBuildingId],
  );
  const overrideUnitSettingsTimeseriesList = analysis.settingsTimeseriesList
    .filter(({ hidden }) => !hidden)
    .map((timeseries) => {
      const matchedTimeseries = timeseriesList.find(
        ({ id }) => id === timeseries.id,
      );
      if (matchedTimeseries) {
        return {
          ...timeseries,
          unitSelected:
            (overrideUnits &&
              overrideUnits[matchedTimeseries.externalId!]?.unit) ||
            matchedTimeseries.unit,
        };
      }
      return timeseries;
    });
  const renderTitle = () => (
    <WidgetHeader
      isDraggable
      text={analysis.title || t("analysis.no-name")}
      onClickRemoveButton={onClickRemoveButton}
      onClickSettingsButton={() =>
        navigate(
          `/asset/${currentBuildingId}/analysis/${
            isScatterplotAnalysis(analysis) ? "scatterplot" : "simple"
          }/${analysis.snapshotId}`,
        )
      }
      addon={
        <PresetTimeSpanSelect
          value={presetTimeSpan || "last7Days"}
          onChange={handleChangePresetTimeSpan}
        />
      }
    />
  );

  const renderContent = () => {
    if (isLoadingOverrideUnit) {
      return (
        <SpinnerWithDelay isLoading={isLoadingOverrideUnit}>
          {t("dashboard.widgets.loading")}
        </SpinnerWithDelay>
      );
    }

    if (isScatterplotAnalysis(analysis)) {
      return (
        <ScatterplotGraph
          width={width}
          height={height - headerHeight}
          granularity={analysis.granularity}
          settingsTimeseriesList={
            overrideUnitSettingsTimeseriesList as SettingsTimeseriesScatterplot[]
          }
          settingsTimeseriesX={analysis.settingsTimeseriesX}
          mergeUnits={analysis.mergeUnits}
          zoomedTimeSpan={timeSpan!}
          tickSize={10}
          interactive
        />
      );
    }

    return (
      <SimpleGraph
        width={width}
        height={height - headerHeight}
        mergeUnits={analysis.mergeUnits}
        settingsTimeseriesList={
          overrideUnitSettingsTimeseriesList as SettingsTimeseriesSimple[]
        }
        timeSpan={timeSpan!}
        tickSize={10}
        interactive
      />
    );
  };

  return (
    <DraggableCard
      style={{ width, height }}
      bordered={false}
      title={renderTitle()}
      bodyStyle={{
        display: "grid",
        placeContent: "center",
        height: `calc(100% - ${headerHeight}px)`,
      }}
    >
      {renderContent()}
    </DraggableCard>
  );
}
